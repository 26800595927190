import React, { useState } from "react";
import "react-multi-carousel/lib/styles.css";

const AnimationBackground = () => {
  const [animationBg, setAnimationBg] = useState(true);

  const Animation = () => (
    <div className="animationBg">
      <div className="shape1"></div>
      <div className="shape2"></div>
      <div className="shape3"></div>
      <div className="shape4"></div>
      <div className="shape5"></div>
    </div>
  );

  return <>{animationBg ? <Animation /> : setAnimationBg(false)}</>;
};

export default AnimationBackground;

import * as Arrow from "Components/ArrowButton";
import { useState, useEffect, useRef, useCallback } from "react";
import { icons } from "Components/VenueMenu/icons";
import Viewer from "Components/Viewer/Viewer";
import SquareButton from "Components/SquareButton/SquareButton";
import clsx from "clsx";

// scope = gallery|units|video
export default function Fullscreen({ venue, content, onCloseModal, scope }) {
  const [contentData] = useState(content.elements);
  const [currentContent, setCurrentContent] = useState({});
  const [imageRefKey, setImageRefKey] = useState(Math.random());
  const imageRef = useRef();
  const imageContainerRef = useRef();
  const videoRef = useRef([]);

  const handleChangeContent = useCallback(
    (inc) => {
      const nextPos = contentData.indexOf(currentContent) + inc;
      const nextPosStep = nextPos < 0 ? contentData.length - 1 : nextPos;
      setCurrentContent(contentData[nextPosStep % contentData.length]);
    },
    [contentData, currentContent],
  );

  const handleNextContent = useCallback(() => {
    setImageRefKey(Math.random());
    handleChangeContent(1);
  }, [handleChangeContent]);

  const handlePreviousContent = useCallback(() => {
    setImageRefKey(Math.random());
    handleChangeContent(-1);
  }, [handleChangeContent]);

  useEffect(() => {
    setCurrentContent(contentData.find((el) => el.id === content.current_id));
    if (videoRef.current && scope === "videos") videoRef.current.play();
  }, [content.current_id, contentData, scope]);

  useEffect(() => {
    const keypress = (e) => {
      if (e.key === "Escape") {
        onCloseModal();
      }
      if (e.key === "ArrowRight") {
        handleNextContent();
      }
      if (e.key === "ArrowLeft") {
        handlePreviousContent();
      }
    };
    window.addEventListener("keyup", keypress);
    return () => window.removeEventListener("keydown", keypress);
  }, [currentContent, handleNextContent, handlePreviousContent, onCloseModal]);

  function zoom(inc) {
    let image = imageRef.current;
    const prevWidth = image.getBoundingClientRect().width;
    const prevHeight = image.getBoundingClientRect().height;
    const resizingWidth = prevWidth * inc;
    const resizingHeight = prevHeight * inc;
    if (resizingWidth > 100 && resizingWidth < 3000) {
      image.style.width = resizingWidth + "px";
      image.style.height = resizingHeight + "px";
    }
    image = null;
  }

  return (
    <section className={clsx("venue-modal_container overflow-hidden bg-[#23303E]", scope)}>
      {scope === "gallery" && (
        <div className="gallery-content">
          <img src={currentContent.url} alt={currentContent.legend} />
        </div>
      )}

      {scope === "videos" && (
        <video controls className="video-content" ref={videoRef}>
          <source src={currentContent.video} type="video/webm" />
          Seu browser não suporta vídeos.
        </video>
      )}

      {scope === "applications" && (
        <iframe className="h-full w-full" src={currentContent.entrypoint} title={currentContent.title} />
      )}

      {scope === "units" && (
        <section className="unit-content_wrapper">
          <div className="unit-content_info">
            <div className="unit-content_info-text">
              {currentContent.area && (
                <h2 style={{ borderBottomColor: venue.colors.logo_background_color }}>
                  {currentContent.area}m<sup>2</sup>
                </h2>
              )}
              <p>{currentContent.name}</p>
              <p>{currentContent.description}</p>
            </div>
          </div>
          <div className="unit-content_image" ref={imageContainerRef}>
            <Viewer
              fontColor={venue.colors.font_color}
              mainColor={venue.colors.primary_color}
              options={{
                viewed() {
                  this.viewer.zoomTo(zoom ? (zoom !== 100 ? +`0.${zoom}` : 1) : 0.5);

                  const el = window;
                  const event = document.createEvent("HTMLEvents");

                  event.initEvent("resize", true, false);
                  el.dispatchEvent(event);
                },
              }}
              secondaryColor={venue.colors.secondary_color}
              zoom={currentContent.zoom}
            >
              <img
                className="base-img"
                key={imageRefKey}
                src={currentContent.image}
                alt={currentContent.description}
                ref={imageRef}
              />
            </Viewer>
          </div>
        </section>
      )}

      {!["applications", "gallery", "units", "videos"].includes(scope) && (
        <section className="venue-content_logo" style={{ backgroundColor: venue.colors.logo_background_color }}>
          <img src={venue.logo_sm_image} alt="" />
          <p
            className="venue-content_logo--subtitle"
            style={{
              backgroundColor: venue.colors.address_background_color,
              color: venue.colors.address_font_color,
            }}
          >
            {venue.address}
          </p>
        </section>
      )}

      {scope === "gallery" && currentContent.preliminary_seal && (
        <div className="venue-modal_on-seal">{icons?.["on_seal"]?.icon?.("#fff")}</div>
      )}

      <SquareButton
        className="absolute -bottom-[7%] z-10 animate-toUp"
        backgroundColor={venue.colors.primary_color}
        onClick={() => onCloseModal(false)}
        title="Fechar"
      >
        {icons?.["close"]?.icon?.(venue.colors.font_color)}
      </SquareButton>

      {!["applications", "videos"].includes(scope) && (
        <>
          <Arrow.Left
            backgroundColor={venue.colors.primary_color}
            className="absolute -left-[4%] z-10 animate-leftToRight"
            iconColor={venue.colors.font_color}
            onClick={handlePreviousContent}
            title="Anterior"
          />
          <Arrow.Right
            backgroundColor={venue.colors.primary_color}
            className="absolute -right-[4%] z-10 animate-rightToLeft"
            iconColor={venue.colors.font_color}
            onClick={handleNextContent}
            title="Próximo"
          />
        </>
      )}

      {scope === "gallery" ? (
        <div className="venue-modal_legend-wrapper" style={{ backgroundColor: venue.colors.primary_color }}>
          <p style={{ color: venue.colors.font_color }}>{currentContent.legend}</p>
        </div>
      ) : (
        !["applications", "units", "videos"].includes(scope) && (
          <div className="venue-modal_image-controls">
            <button
              type="button"
              className="zoom-in"
              onClick={() => zoom(1.5)}
              style={{
                backgroundColor: venue.colors.primary_color,
                color: venue.colors.font_color,
              }}
            >
              +
            </button>
            <button
              type="button"
              className="zoom-out"
              onClick={() => zoom(0.5)}
              style={{
                backgroundColor: venue.colors.secondary_color,
                color: venue.colors.font_color,
              }}
            >
              -
            </button>
          </div>
        )
      )}
    </section>
  );
}

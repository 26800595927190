import Ventures from "./components/Ventures";
import VenturesListContainer from "./components/VenturesListContainer";

const VenturesList = () => (
  <VenturesListContainer>
    <Ventures />
  </VenturesListContainer>
);

export default VenturesList;

import { contexts } from "App.contexts";
import clsx from "clsx";
import { useStateContext } from "react-context-x";
import VenturesListContextContextProvider from "../contexts/VenturesListContext";

const VenturesListContainer = ({ children }) => {
  const activeList = useStateContext(contexts, "activeList");

  return (
    <VenturesListContextContextProvider>
      <div
        className={clsx(
          "absolute -z-[1] flex h-full w-full flex-col items-center justify-center bg-[url('./Media/bgLista.png')] bg-[length:118%] bg-center bg-no-repeat opacity-0",
          activeList ? "bgListaFilters active" : "bgListaFilters",
        )}
      >
        {children}
      </div>
    </VenturesListContextContextProvider>
  );
};

export default VenturesListContainer;

import clsx from "clsx";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toKebab } from "../../Utils/toKebab";
import { icons } from "../VenueMenu/icons";
import "./VenueMenu.scss";

export default function VenueMenu({
  mainColor = ["", ""],
  activeColor = ["", ""],
  fontColor = "",
  activeFontColor = "",
  borderColor = "",
  itens = [],
  active = "",
  onChangePage = () => {},
}) {
  const [activeMenuItem, setActiveMenuItem] = useState("image");

  function handleSelectItem(el) {
    onChangePage();
    setActiveMenuItem(el);
  }

  return (
    <>
      <nav className="venue-menu_wrapper">
        {itens.map((el, index) => (
          <div
            key={el}
            className={clsx(
              "venue-menu_item-container w-[12%] !py-2 lg:w-[10%] lg:!py-[10px] xl:w-[8%]",
              el === activeMenuItem ? "active" : "",
            )}
            style={{
              background:
                el === activeMenuItem
                  ? `linear-gradient(145deg, ${activeColor[0]} 50%, ${activeColor[1]} 100%)`
                  : `linear-gradient(120deg, ${mainColor[0]} 60%, ${mainColor[1]})`,
              borderColor: borderColor,
            }}
            data-id={index}
          >
            <Link to={toKebab(icons?.[el]?.name)} onClick={() => handleSelectItem(el)}>
              <ul className="venue-menu_item">
                <li className="mb-[6px]">{icons?.[el]?.icon?.(el === activeMenuItem ? activeFontColor : fontColor)}</li>
                <li
                  className="text-center text-xs leading-[14px] lg:text-[13px]"
                  style={{
                    color: el === activeMenuItem ? activeFontColor : fontColor,
                  }}
                >
                  {icons?.[el]?.name}
                </li>
              </ul>
            </Link>
          </div>
        ))}
      </nav>
    </>
  );
}

import { ReactComponent as SearchIcon } from "Media/search.svg";
import { useVenturesSearchContext } from "../contexts/VenturesSearchContext";

const SearchButton = () => {
  const { listLoading } = useVenturesSearchContext();

  return (
    <button
      className="absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 opacity-60 lg:right-4 lg:h-6 lg:w-6"
      disabled={listLoading}
      title="Buscar empreendimentos"
      type="submit"
    >
      <SearchIcon />
    </button>
  );
};

export default SearchButton;

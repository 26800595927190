import { combineReducers } from "redux";
import { institutionalSlice } from "./institutional/institutionalSlice";
import { neighborhoodSlice } from "./neighborhoods/neighborhoodSlice";
import { regionSlice } from "./regions/regionSlice";
import { venueSlice } from "./venues/venueSlice";

export default combineReducers({
  institutional: institutionalSlice.reducer,
  neighborhoods: neighborhoodSlice.reducer,
  regions: regionSlice.reducer,
  venues: venueSlice.reducer,
});

import styled from "styled-components";

export const Wrapper = styled.div({
  position: 'absolute',
  zIndex: '4',
  height: 'calc(100% - 75px)',
  width: '100%',
  display: 'flex',
  ".rpv-core__inner-page": {
    backgroundColor: 'transparent',
  },
  ".rpv-core__inner-pages, .rpv-thumbnail__list": {
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  ".rpv-core__inner-pages::-webkit-scrollbar, .rpv-thumbnail__list::-webkit-scrollbar": {
    display: 'none',
  },
  ".rpv-thumbnail__list": {
    padding: 0,
    rowGap: 3,
  },
  ".rpv-core__page-layer": {
    right: 50,
  },
});

export const ViewerContainer = styled.div({
  height: "90%",
  flex: 1,
  alignSelf: 'center',
});

export const PreviousButtonContainer = styled.div({
  left: 0,
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  zIndex: 1,
});

export const NextButtonContainer = styled.div({
  right: '15%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  zIndex: 1,
});

export const ZoomButtonContainer = styled.div({
  right: '15%',
  position: 'absolute',
  bottom: 0,
  transform: 'translate(0, -50%)',
  zIndex: 1,
});

export const Button = styled.div(({ background, font }) => ({
  backgroundColor: background,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: font,
  borderRadius: 0,
  width: 60,
  height: 60,
  boxShadow: '0 18px 26px 0 #141521',
  cursor: 'pointer',
  "svg": {
    fill: font,
  }
}));

export const ThumbnailsContainer = styled.div({
  overflow: 'auto',
  width: '15%',
  height: 'calc(100% + 75px)',
  marginTop: '-2.45%',
  position: 'absolute',
  zIndex: 9,
  right: 0,
  backgroundColor: '#1e2933',
  "::before": {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
    background: 'linear-gradient(180deg, rgba(0,0,0,0.8) 0%, transparent 100%)',
    height: 25,
    content: '""',
  },
  "::after": {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 1,
    background: 'linear-gradient(180deg, transparent 0%, rgba(0,0,0,0.8) 100%)',
    height: 25,
    content: '""',
  },
});

export const Thumbnail = styled.div(({ color }) => ({
  backgroundColor: 'transparent',
  position: 'relative',
  cursor: 'pointer',
  width: '100%',
  overflow: 'hidden',
  ".rpv-thumbnail__container": {
    marginLeft: '50%',
    transform: 'translate(-50%)',
    width: '100% !important',
    height: '100% !important',
    maxHeight: 100,
    overflow: 'hidden',
  },
  "img": {
    maxWidth: 'inherit',
    width: 'auto',
    height: 180,
  },
  ".opacity-layer": {
    backgroundColor: color,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    opacity: 0.4,
  },
}));

import { icons } from "Components/VenueMenu/icons";
import { useGlobalContext } from "contexts/GlobalContext";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import "./UpperMenu.scss";

const FullscreenButtonLabel = {
  Enter: "Ver em tela cheia",
  Exit: "Sair da tela cheia",
};

export default function UpperMenu() {
  const [fullscreenButtonLabel, setFullscreenButtonLabel] = useState(FullscreenButtonLabel.Enter);
  const { isFullScreen, toggleFullScreen } = useGlobalContext();

  let history = useHistory();
  let empreendimento = useRouteMatch("/empreendimentos/:slug");
  let empreendimentos = useRouteMatch("/empreendimentos");
  let sobreNos = useRouteMatch("/sobre-nos");

  const handleBackButton = () => {
    if (empreendimento && empreendimento.isExact) {
      history.push("/empreendimentos");
    }

    if (empreendimentos && empreendimentos.isExact) {
      history.push("/");
    }

    if (sobreNos && sobreNos.isExact) {
      history.push("/");
    }
  };

  const verifyRouteMatch = () => {
    if (
      (empreendimento && empreendimento.isExact) ||
      (empreendimentos && empreendimentos.isExact) ||
      (sobreNos && sobreNos.isExact)
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!isFullScreen) {
      setFullscreenButtonLabel(FullscreenButtonLabel.Enter);
    }

    if (isFullScreen) {
      setFullscreenButtonLabel(FullscreenButtonLabel.Exit);
    }
  }, [isFullScreen]);

  return (
    <>
      {verifyRouteMatch() && (
        <nav className="upper-right-menu absolute top-0 right-0 z-10 h-14 w-14 overflow-visible bg-transparent xl:h-16 xl:w-16 2xl:h-20 2xl:w-20">
          <div
            className="upper-right-menu_item logo absolute flex cursor-pointer items-center justify-center rounded-full border-2 border-solid border-[#1d4ae2] bg-[#0155a8] [&.logo]:top-0 [&.logo]:right-0 [&.logo]:h-full [&.logo]:w-full [&.logo]:border-none [&.logo]:xl:-top-[5%] [&.logo]:xl:-right-[5%] [&.logo_svg]:w-3/4"
            onClick={() => history.push("/")}
          >
            {icons?.["menu_logo"]?.icon?.("#FFF")}
          </div>
          <button
            className="upper-right-menu_item back close absolute top-[78%] flex h-1/2 w-1/2 -translate-y-1/2 -translate-x-4 items-center justify-center rounded-full [&_svg]:translate-y-[1px] [&_svg]:xl:h-[54%] [&_svg]:xl:w-[54%]"
            onClick={handleBackButton}
            title="Voltar"
            type="button"
          >
            {icons?.["menu_back"]?.icon?.("#FFF")}
          </button>
          <button
            className="upper-right-menu_item fullscreen absolute left-[72%] bottom-0 flex h-[40%] w-[40%] -translate-x-1/2 translate-y-3 items-center justify-center rounded-full [&_svg]:pointer-events-none [&_svg]:h-1/2 [&_svg]:w-1/2"
            onClick={toggleFullScreen}
            title={fullscreenButtonLabel}
            type="button"
          >
            {icons?.["menu_fullscreen"]?.icon?.("#FFF")}
          </button>
        </nav>
      )}
    </>
  );
}

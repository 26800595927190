import clsx from "clsx";
import { useInstitutionalCarouselContext } from "../contexts/InstitutionalCarouselContext";
import Button from "./Button";

const MenuItem = ({ onClick, ...restProps }) => {
  const { menu } = useInstitutionalCarouselContext();
  const { active, index } = restProps;
  const title = menu[index].name;

  return (
    <li className="flex-1">
      <Button
        className={clsx("h-full", active ? "translate-y-[6px] before:bg-black/20" : "")}
        onClick={onClick}
        title={title}
      >
        {title}
      </Button>
    </li>
  );
};

export default MenuItem;

import clsx from "clsx";
import VitaconLogo from "./VitaconLogo";

const Container = ({ children, className }) => {
  return (
    <div
      className={clsx(
        "fixed inset-0 z-40 flex h-screen w-screen items-center justify-center before:absolute before:-z-10 before:block before:h-full before:w-full before:bg-black",
        className,
      )}
    >
      <div className="w-[80vw] text-center">
        <VitaconLogo />
        <p className="mt-8 text-lg tracking-wide text-white">{children}</p>
      </div>
    </div>
  );
};

export default Container;

import { createSlice } from "@reduxjs/toolkit";
import setLoadingState from "Utils/setLoadingState";

const getContentUrl = (path) => `${process.env.REACT_APP_INTERNAL_URL}/institucional/${path}`;

const initialState = {
  actionsLoading: false,
  lastError: null,
  listLoading: false,
  menu: [
    {
      content: getContentUrl("01/base.webp"),
      id: 1,
      name: "Manifesto",
      type: "img",
    },
    {
      content: getContentUrl("02/base.html"),
      id: 2,
      name: "Vitacon em Números",
      type: "iframe",
    },
    {
      content: getContentUrl("03/base.html"),
      id: 3,
      name: "Pilares",
      type: "iframe",
    },
    {
      content: getContentUrl("04/base.html"),
      id: 4,
      name: "Rentabilidade",
      type: "iframe",
    },
    {
      content: getContentUrl("05/base.html"),
      id: 5,
      name: "Powered by Housi",
      type: "iframe",
    },
    {
      content: getContentUrl("06/base.html"),
      id: 6,
      name: "iBuyer",
      type: "iframe",
    },
    {
      content: getContentUrl("07/base.html"),
      id: 7,
      name: "Reinventa SP",
      type: "iframe",
    },
  ],
  totalCount: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const institutionalSlice = createSlice({
  initialState: initialState,
  name: "institutional",
  reducers: {
    catchError: (state, { payload: { callType, error }, type }) => {
      state = {
        ...state,
        ...setLoadingState({ callType, loadingState: false, state }),
        ...{ error: `${type}: ${error}` },
      };
    },

    startCall: (state, { payload: { callType } }) => {
      state = {
        ...state,
        ...setLoadingState({ callType, loadingState: true, state }),
        ...{ error: null },
      };
    },

    menuFetched: (state, { payload: { menu, totalCount } }) => {
      state.error = null;
      state.listLoading = false;
      state.menu = menu;
      state.totalCount = totalCount;
    },
  },
});

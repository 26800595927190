import AboutUsLink from "Components/AboutUsLink";
import HomeIframe from "Components/HomeIframe";
import PageContainer from "Components/PageContainer";
import VenturesLink from "Components/VenturesLink";

const PageInitial = () => {
  return (
    <PageContainer className="initial_container">
      <AboutUsLink />
      <HomeIframe />
      <VenturesLink />
    </PageContainer>
  );
};

export default PageInitial;

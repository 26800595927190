import clsx from "clsx";
import { Link } from "react-router-dom";

const VerticalLink = ({ children, className, to }) => {
  return (
    <Link className={clsx("absolute h-full w-[5%] md:w-[3.854%]", className)} to={to}>
      {children}
    </Link>
  );
};

export default VerticalLink;

import { useStateContext } from "react-context-x";
import { contexts } from "../../App.contexts";

const VenuePageLoader = () => {
  const loadingVenuePage = useStateContext(contexts, "loadingVenuePage");

  const BackDrop = () => (
    <div className="w-[110vw] h-screen absolute bg-black z-[1000] -left-[10%] opacity-0 animate-[fade_900ms_linear_0s_1_forwards] [&.hideBlack]:-z-10 [&.hideBlack]:hidden"></div>
  );

  return <>{loadingVenuePage && <BackDrop />}</>;
};

export default VenuePageLoader;

import VenturesListLoader from "Components/VenturesList/components/VenturesListLoader";
import VenuePageLoader from "Components/VenuePageLoader/VenuePageLoader";
import { useSelector } from "react-redux";

const EnterprisesPreloaders = () => {
  const { listLoading } = useSelector(({ venues: { listLoading } }) => ({ listLoading }));

  return (
    <>
      <VenuePageLoader />
      {listLoading && <VenturesListLoader />}
    </>
  );
};

export default EnterprisesPreloaders;

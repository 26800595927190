import clsx from "clsx";

const Button = ({ children, className, onClick, title }) => {
  return (
    <button
      className={clsx(
        "relative flex w-full items-center justify-center rounded-t bg-gradient-to-b from-[#cc2222] to-[#9b1a1a] p-3 text-[8px] uppercase tracking-wider text-white transition-all delay-100 duration-200 before:pointer-events-none before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-black/0 before:transition-all before:delay-150 before:duration-200 hover:translate-y-[6px] active:shadow-[inset_0_0_10px_0_rgba(0,0,0,0.8)] xl:p-4 xl:text-xs",
        className,
      )}
      onClick={onClick}
      title={title}
      type="button"
    >
      {children}
    </button>
  );
};

export default Button;

import clsx from "clsx";
import Checkbox from "Components/Checkbox/Checkbox";
import { icons } from "Components/VenueMenu/icons";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchVenues } from "redux/reducers/venues/venueActions";
import "./Filters.scss";

const Filters = () => {
  const {
    neighborhoods,
    regions,
    venues: { venueNeighborhoodIds },
  } = useSelector((state) => state, shallowEqual);

  const [checkAll, setCheckAll] = useState(false);
  const [selectedRegions, selectRegions] = useState([]);
  const [profiles] = useState([]);
  const [filteredNeighborhoods, setFilteredNeighborhoods] = useState([]);
  const [active, setActive] = useState(false);
  const [office] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedRegions.length && regions.totalCount) {
      const allRegions = regions.entities.reduce((carryRegionNames, region) => {
        carryRegionNames.push(region.name);

        return carryRegionNames;
      }, []);

      selectRegions(allRegions);
    }
  }, [regions, selectedRegions.length]);

  useEffect(() => {
    let preFilteredNeighborhoods = neighborhoods.entities.filter((neighborhood) =>
      selectedRegions.includes(neighborhood.region),
    );

    if (venueNeighborhoodIds.length) {
      preFilteredNeighborhoods = JSON.parse(JSON.stringify(preFilteredNeighborhoods));

      preFilteredNeighborhoods.forEach(({ id }, index) => {
        if (venueNeighborhoodIds.includes(id)) {
          preFilteredNeighborhoods[index].checked = true;
        }
      });
    }

    setFilteredNeighborhoods(preFilteredNeighborhoods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedRegions)]);

  const checkNeighborhood = (event) => {
    let needCheckAll = false;
    let checked = 0;

    let checkedNeighborhoods = filteredNeighborhoods.map((neighborhood) => {
      if (neighborhood.id === event.target.value) {
        if (event.target.checked) {
          checked++;
        }

        return {
          ...neighborhood,
          checked: event.target.checked,
        };
      }

      if (neighborhood.checked) {
        checked++;
      }

      return neighborhood;
    });

    if (checked === checkedNeighborhoods.length) {
      needCheckAll = true;
    }

    setFilteredNeighborhoods(checkedNeighborhoods);
    setCheckAll(needCheckAll);
  };

  const checkAllNeighborhood = (event) => {
    let checkedNeighborhoods = filteredNeighborhoods.map((neighborhood) => ({
      ...neighborhood,
      checked: event.target.checked,
    }));

    setFilteredNeighborhoods(checkedNeighborhoods);
    setCheckAll(event.target.checked);
  };

  const toggleRegion = (name) => {
    const regionIndex = selectedRegions.indexOf(name);

    if (regionIndex > -1) {
      let regionFiltered = selectedRegions;

      if (regionFiltered.length > 1) {
        regionFiltered.splice(regionIndex, 1);
      }

      selectRegions(regionFiltered);
      setFilteredNeighborhoods(
        neighborhoods.entities.filter((neighborhood) => regionFiltered.includes(neighborhood.region)),
      );
    } else {
      let regionFiltered = selectedRegions;
      regionFiltered.push(name);

      selectRegions(regionFiltered);
      setFilteredNeighborhoods(
        neighborhoods.entities.filter((neighborhood) => regionFiltered.includes(neighborhood.region)),
      );
    }
  };

  const submitFilter = () => {
    const venueNeighborhoodIds = filteredNeighborhoods.reduce((result, neighborhood) => {
      if (neighborhood.checked === true) {
        return result.concat([neighborhood.id]);
      }
      return result;
    }, []);

    dispatch(fetchVenues({ office, profiles, updateOthers: false, venueNeighborhoodIds }));
    setActive(false);
  };

  return (
    <div className={clsx("filters", active ? "active !z-20 !mt-20 !h-full py-10 px-5 md:px-10" : "")}>
      <div className={clsx("btn-filters_wrapper", active ? "open" : "closed pointer-events-none")}>
        <button
          className={clsx(
            "btn-filters_button flex items-center justify-center",
            active
              ? "open rounded-b-md bg-red-500 p-4"
              : "closed pointer-events-auto rounded-t-md bg-gradient-to-b from-[#3f79c7] to-[#2d64a8] py-1 px-6 text-shadow-filter-button lg:py-4 lg:px-5 [&_p:nth-child(1)]:border [&_p:nth-child(1)]:border-solid [&_p:nth-child(1)]:border-white [&_p:nth-child(2)]:!text-sm [&_p:nth-child(2)]:lg:!text-base [&_p:nth-child(2)]:xl:!text-xl",
          )}
          onClick={() => setActive(!active)}
          type="button"
        >
          <div className={clsx("inline-block [&_svg]:w-full", active ? "w-[11px]" : "mr-1 -ml-1 w-7 lg:mr-2 lg:w-9")}>
            {icons?.[active ? "close" : "filters"]?.icon?.("#FFF")}
          </div>
          {!active && <p className="m-0 break-all p-0 text-center text-white">Filtro por bairro</p>}
        </button>
      </div>
      <div className="filter-content_wrapper mt-2 lg:mt-0">
        <div className="-mt-1 flex h-full flex-col justify-center">
          <section className="filter-content upper h-full lg:flex lg:w-full lg:items-center">
            <div className="relative h-full w-full justify-center lg:flex lg:h-[86%] lg:flex-col">
              <div className="region-select_wrapper relative top-4 text-center">
                {regions.entities.map((region, i) => (
                  <button
                    className={clsx(
                      "region-select_button leading-1 relative mr-[2px] inline-block w-1/5 cursor-pointer overflow-visible rounded bg-[linear-gradient(3deg,#1b252f_0%,#23303e_100%)] px-1 py-2 text-center indent-[0.1rem] text-xs font-normal uppercase tracking-widest before:absolute before:left-1/2 before:top-1/2 before:z-10 before:h-[calc(100%_-_1px)] before:w-[calc(100%_-_1px)] before:-translate-x-1/2 before:-translate-y-1/2 before:rounded lg:text-sm",
                      selectedRegions.indexOf(region.name) > -1
                        ? "region-select_button--active bg-blue-button-radial text-white shadow-[15px_12px_32px_2px_rgba(0,0,0,0.5)] before:bg-blue-button"
                        : "text-[rgba(255,255,255,0.2)]",
                    )}
                    key={i}
                    onClick={() => toggleRegion(region.name)}
                    type="button"
                  >
                    <span className="relative top-[1px] z-10 inline-block text-shadow-fog lg:top-0">
                      {region.display}
                    </span>
                  </button>
                ))}
              </div>
              <div className="neighborhood-wrapper flex h-[86%] min-h-[86%] flex-col justify-center rounded-2xl border border-[#5a80af] pr-4 lg:px-20 lg:pt-6 lg:pb-14">
                <ul className="neighborhood-items flex max-h-[90%] w-auto flex-wrap overflow-auto pl-4 pr-8 pt-5 lg:max-h-[44rem] lg:flex-col lg:overflow-hidden">
                  <li className="mb-6 w-1/2 list-none lg:mt-8 lg:w-1/4">
                    <label>
                      <Checkbox checked={checkAll} onChange={checkAllNeighborhood} />
                      <span className="ml-4 text-sm font-semibold uppercase tracking-wider text-white text-shadow-fog">
                        Todos
                      </span>
                    </label>
                  </li>
                  {filteredNeighborhoods.map((neighborhood, i) => (
                    <li
                      className="w-1/2 list-none lg:w-1/4 lg:last:mt-8 lg:last:mb-6 [&:not(:last-child)]:mb-6 md:[&:not(:last-child)]:mt-8"
                      key={i}
                    >
                      <label>
                        <Checkbox checked={neighborhood.checked} onChange={checkNeighborhood} value={neighborhood.id} />
                        <span className="ml-4 text-sm font-light uppercase tracking-wider text-white text-shadow-fog">
                          {neighborhood.name}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
          <section className="filter-content bottom mt-1 lg:mt-0 lg:-translate-y-2">
            <div className="filter-bottom_wrapper flex h-full w-full justify-center">
              <button
                className="active relative h-full w-[20%] cursor-pointer overflow-visible rounded border-white bg-pearl-button-border py-1 indent-[3px] text-sm font-normal uppercase tracking-[3px] text-[#2c5588] shadow-xl before:absolute before:left-1/2 before:top-1/2 before:z-10 before:h-[calc(100%_-_1px)] before:w-[calc(100%_-_1px)] before:-translate-x-1/2 before:-translate-y-1/2 before:rounded before:bg-pearl-button after:absolute after:left-1/2 after:top-1/2 after:-z-10 after:h-full after:w-full after:-translate-x-1/2 after:-translate-y-1/2 after:rounded after:shadow-pearl-button-shine lg:w-[26%] lg:py-3 lg:text-base"
                onClick={() => submitFilter()}
                type="button"
              >
                <span className="relative z-10">Filtrar</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Filters;

import PinMap from "Components/PinMap/PinMap";
import { NoResults } from "Components/VenturesSearch";
import L from "leaflet";
import OnSvg from "Media/on.svg";
import { useState } from "react";
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { shallowEqual, useSelector } from "react-redux";

const iconPerson = new L.Icon({
  popupAnchor: [-33, 30],
  iconUrl: OnSvg,
  iconSize: new L.Point(60, 75),
});

const VenturesMap = () => {
  const [map, setMap] = useState(false);

  const { venues } = useSelector(
    ({ venues }) => ({
      venues,
    }),
    shallowEqual,
  );

  const position = [-23.575294789539495, -46.678121585724185];

  const closePopups = () => {
    if (!map) return;

    map.closePopup();
  };

  return (
    <div className="bgMapFilters absolute left-0 top-0 flex h-full w-full -translate-x-[30px] animate-fadeSlide bg-[url('./Media/mapaBg.png')] bg-center bg-no-repeat opacity-0">
      <NoResults className="font-bold text-[#1B252F] drop-shadow" />
      <div className="Map">
        <MapContainer className="h-full w-full" center={position} zoom={14} scrollWheelZoom={true} whenCreated={setMap}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=uvY9CN57pHTXnjcrQDe2"
          />
          <FeatureGroup>
            {venues.entities.map((venue, i) => (
              <Marker key={`markers-${i}`} position={[venue.coordenates.lat, venue.coordenates.lng]} icon={iconPerson}>
                <Popup className="[&_.leaflet-popup-content]:h-[26vw] [&_.leaflet-popup-content]:max-h-[250px] [&_.leaflet-popup-content]:!w-[24vw] [&_.leaflet-popup-content]:max-w-[250px] [&_.leaflet-popup-close-button]:opacity-0 [&_*]:!text-white">
                  <PinMap isOpened venue={venue} close={() => closePopups()} />
                </Popup>
              </Marker>
            ))}
          </FeatureGroup>
        </MapContainer>
      </div>
    </div>
  );
};

export default VenturesMap;

import EnterprisesPageContainer from "Components/EnterprisesPageContainer";
import EnterprisesPreloaders from "Components/EnterprisesPreloaders";
import Filters from "Components/Filters";
import ListMapButtons from "Components/ListMapButtons";
import VenturesList from "Components/VenturesList";
import VenturesMap from "Components/VenturesMap";
import VenturesSearch, { VenturesSearchContextProvider } from "Components/VenturesSearch";
import { useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { useDispatch } from "react-redux";
import { fetchVenues } from "redux/reducers/venues/venueActions";

const Enterprises = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(fetchVenues()), []);

  return (
    <>
      <EnterprisesPreloaders />
      <EnterprisesPageContainer>
        <VenturesSearchContextProvider>
          <ListMapButtons />
          <VenturesSearch />
          <div className="absolute h-[96%] w-[96%] overflow-hidden rounded-[9px] shadow-[0_22px_52px_rgba(0,0,0,0.8)]">
            <VenturesMap />
            <VenturesList />
          </div>
          <Filters />
        </VenturesSearchContextProvider>
      </EnterprisesPageContainer>
    </>
  );
};

export default Enterprises;

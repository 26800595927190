import clsx from "clsx";
import Pulse from "Components/Pulse";

const Button = ({ children, className, onClick }) => (
  <button
    className={clsx(
      "buttonPin relative cursor-pointer rounded border-none bg-[linear-gradient(145deg,#1969cc_0%,#245086_100%)] py-2 px-10 text-sm uppercase tracking-widest text-white shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]",
      className,
    )}
    onClick={onClick}
    type="button"
  >
    <Pulse />
    {children}
  </button>
);

export default Button;

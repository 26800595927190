import * as Arrow from "Components/ArrowButton";
import MultiCarousel from "react-multi-carousel";
import useCarousel from "../hooks/useCarousel";
import MenuItem from "./MenuItem";

const Carousel = ({ children }) => {
  const { arrowLeftRef, arrowRightRef, responsive } = useCarousel();

  return (
    <MultiCarousel
      containerClass="!items-start"
      customDot={<MenuItem />}
      customLeftArrow={
        <Arrow.Left
          className="absolute left-0 top-1/2 z-[2] translate-y-[calc(-50%-21px)] hover:!-translate-y-12"
          ref={arrowLeftRef}
          title="Página anterior"
        />
      }
      customRightArrow={
        <Arrow.Right
          className="absolute right-0 top-1/2 z-[2] translate-y-[calc(-50%-21px)] hover:!-translate-y-12"
          ref={arrowRightRef}
          title="Próxima página"
        />
      }
      dotListClass="space-x-1 w-[96%] !left-1/2 -translate-x-1/2"
      itemClass="h-full"
      responsive={responsive}
      showDots={true}
      sliderClass="h-[calc(100%-42px)]"
    >
      {children}
    </MultiCarousel>
  );
};

export default Carousel;

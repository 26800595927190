import Content from "./Content";

const Item = ({ content, name, type }) => {
  const isImg = type === "img";

  const itemProps = {
    src: content,
    title: name,
  };

  if (isImg) {
    itemProps.children = <img alt={name} className="h-full w-full object-contain" src={content} />;
  }

  return <Content asChild={isImg} {...itemProps} />;
};

export default Item;

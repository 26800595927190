import { useCallback, useEffect, useRef } from "react";
import { useInstitutionalCarouselContext } from "../contexts/InstitutionalCarouselContext";

const useCarousel = () => {
  const { responsive } = useInstitutionalCarouselContext();
  const arrowLeftRef = useRef(null);
  const arrowRightRef = useRef(null);
  const clickButton = useCallback((buttonRef) => (buttonRef.current ? buttonRef.current.click() : ""), []);

  const onKeyUp = useCallback(
    (event) => {
      if (event.key === "ArrowLeft") {
        clickButton(arrowLeftRef);
      }

      if (event.key === "ArrowRight") {
        clickButton(arrowRightRef);
      }
    },
    [clickButton],
  );

  useEffect(() => {
    window.addEventListener("keyup", onKeyUp);

    return () => window.removeEventListener("keyup", onKeyUp);
  }, [onKeyUp]);

  return { arrowLeftRef, arrowRightRef, responsive };
};

export default useCarousel;

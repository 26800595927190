import { useCallback, useEffect, useState } from "react";

const useFullscreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const getRequestFullScreen = () => {
    return (
      document.documentElement.requestFullscreen ||
      document.documentElement["mozRequestFullscreen"] ||
      document.documentElement["msRequestFullscreen"] ||
      document.documentElement["webkitRequestFullscreen"]
    );
  };

  const getExitFullscreen = () =>
    document["webkitExitFullscreen"] || document["msExitFullscreen"] || document.exitFullscreen;

  const toggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement && getRequestFullScreen()) {
      return Promise.resolve(getRequestFullScreen().call(document.documentElement));
    }

    return Promise.resolve(getExitFullscreen().call(document));
  }, []);

  const useCustomFullScreen = useCallback(
    (event) => {
      if (event.key === "F11") {
        event.preventDefault();
        toggleFullScreen();
      }
    },
    [toggleFullScreen],
  );

  const watchFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      return setIsFullScreen(false);
    }

    if (document.exitFullscreen) {
      setIsFullScreen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", useCustomFullScreen);
    document.addEventListener("fullscreenchange", watchFullscreen);

    return () =>
      window.removeEventListener("keydown", useCustomFullScreen) &&
      document.removeEventListener("fullscreenchange", watchFullscreen);
  }, [useCustomFullScreen, watchFullscreen]);

  return { isFullScreen, toggleFullScreen };
};

export default useFullscreen;

export const toKebab = (string) => {
    if (string) {
        return string
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/([a-z])([A-Z])/g, "$1-$2")
          .replace(/\s+/g, "-")
          .toLowerCase();
    }
  }

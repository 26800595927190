import styled from "styled-components";

export const MapplicContainer = styled.div({
  height: "100%",
  width: "100%",
});

export const MapplicInstance = styled.div(({ activeFontColor, fontColor, mainColor }) => ({
  height: "100%",
  width: "100%",
  "& .mapplic-pin": {
    background: `linear-gradient(120deg, ${mainColor[0]} 60%, ${mainColor[1]}) !important`,
    boxShadow: "0px 8px 16px #000000ba",
    "&:before": {
      borderColor: mainColor[0],
    },
  },
  "& .mapplic-zoom-buttons button.mapplic-disabled": {
    backgroundColor: mainColor[1],
  },
  "& .mapplic-levels button.mapplic-disabled": {
    backgroundColor: mainColor[1],
  },
  "& .mapplic-list-location": {
    "& h4": {
      color: fontColor,
    },
    "&.mapplic-active h4, & a:hover h4": {
      color: activeFontColor,
    },
  },
  "& .mapplic-sidebar": {
    "&::-webkit-scrollbar-thumb": {
      borderColor: mainColor[1],
      backgroundColor: fontColor,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: mainColor[1],
    },
  },
}));

import { contexts } from "App.contexts";
import clsx from "clsx";
import { ReactComponent as BackIcon } from "Media/back.svg";
import { useStateContext } from "react-context-x";
import { useVenturesSearchContext } from "../contexts/VenturesSearchContext";
import useVenturesSearch from "../hooks/useVenturesSearch";

const BackButton = () => {
  const activeList = useStateContext(contexts, "activeList");
  const { isUsingSearch } = useVenturesSearchContext();
  const { backToVenturesList } = useVenturesSearch();

  const Button = () => (
    <button
      className={clsx(
        "mr-6 flex items-center text-sm uppercase tracking-widest transition-all delay-1000 duration-500",
        !activeList ? "font-bold text-[#1B252F] [&_svg]:fill-[#1B252F]" : "text-white",
      )}
      onClick={backToVenturesList}
      title="Voltar à lista completa"
      type="button"
    >
      <BackIcon className="mr-3 h-6 w-6" /> Voltar
    </button>
  );

  return <>{isUsingSearch && <Button />}</>;
};

export default BackButton;

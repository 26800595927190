import clsx from "clsx";
import { forwardRef } from "react";

const SquareButton = forwardRef(({ backgroundColor, children, className, onClick, title }, ref) => (
  <button
    className={clsx(
      "flex h-9 w-9 cursor-pointer items-center justify-center border-none bg-[#C62222] shadow-[0_20px_10px_0_rgba(0,0,0,0.5)] transition-all delay-150 ease-in-out hover:translate-y-1 hover:shadow-[0_0_10px_0_rgba(0,0,0,0.5)] active:shadow-[inset_0_0_10px_0_rgba(0,0,0,0.8)] xl:h-[7%] xl:w-[4%] [&:active_svg]:scale-90 [&:active_svg]:opacity-95",
      className,
    )}
    onClick={onClick}
    ref={ref}
    style={backgroundColor ? { backgroundColor } : null}
    title={title}
    type="button"
  >
    {children}
  </button>
));

export default SquareButton;

import clsx from "clsx";
import { useVenturesSearchContext } from "../contexts/VenturesSearchContext";

const SearchField = () => {
  const { listLoading, setSearchFieldValue, searchFieldValue } = useVenturesSearchContext();
  const onChange = (event) => setSearchFieldValue(event.target.value);

  return (
    <input
      aria-label="Busque empreendimentos por palavras chave"
      className={clsx(
        "min-w-[480px] rounded-md border border-transparent bg-[#1B252F] py-2 pl-2 pr-7 text-white shadow-inner outline-0 lg:rounded-lg lg:py-4 lg:pl-4 lg:pr-14 [&:focus]:border-[#1A67C6] [&:focus_+_button_svg]:fill-[#1A67C6] [&:focus_+_button]:opacity-100",
        listLoading ? "text-white/50" : "",
      )}
      disabled={listLoading}
      onChange={onChange}
      placeholder="Busca"
      type="text"
      value={searchFieldValue}
    />
  );
};

export default SearchField;

import { useGlobalContext } from "contexts/GlobalContext";
import vitaconLogo from "../Media/vitacon_full_logo.png";

export default function EnterFullscreenMode({ onPostToggle = () => {}, onClose = () => {} }) {
  const { toggleFullScreen } = useGlobalContext();

  const handleFullScreenMode = async () => {
    await toggleFullScreen();

    onPostToggle();
    onClose();
  };

  return (
    <section className="fullscreen-mode_wrapper">
      <div className="fullscreen-mode_container">
        {/* this is a good hack */}
        <fieldset>
          <legend align="center">bem-vindo ao TouchApp</legend>
          <img src={vitaconLogo} alt="Vitacon" />
        </fieldset>
        <p className="title_1">
          para sua melhor experência <br /> a navegação será feita em tela cheia
        </p>
        <p className="title_2">
          Esta apresentação é melhor visualizada em telas maiores
          <br /> como computadores ou tablets
        </p>
        <button onClick={handleFullScreenMode} title="Prosseguir em tela cheia" type="button">
          Prosseguir
        </button>
      </div>
    </section>
  );
}

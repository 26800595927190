import { createContexts } from "react-context-x";

const states = {
  activeList: true,
  currentPage: "inicial",
  filters: {
    bairros: "",
    objetivo: "",
    metragem: "",
    features: "",
  },
  loadingVenuePage: false,
};

export const contexts = createContexts(states);

const setLoadingState = ({ callType, loadingState, state }) => {
  let loadingProp = "listLoading";

  if (callType === "action") {
    loadingProp = "actionsLoading";
  }

  state[loadingProp] = loadingState;

  return state;
};

export default setLoadingState;

import React, { createContext, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const VenturesSearchContext = createContext({});
VenturesSearchContext.displayName = "VenturesSearchContext";

const VenturesSearchContextProvider = (props) => {
  const { children } = props;
  const { entities, listLoading, search, searching } = useSelector(({ venues }) => venues);
  const [searchFieldValue, setSearchFieldValue] = useState(search);
  const [isUsingSearch, setIsUsingSearch] = useState(searching);
  const showNoResultsMessage = useMemo(() => !entities?.length && isUsingSearch, [entities?.length, isUsingSearch]);

  const value = useMemo(
    () => ({
      ...props,
      isUsingSearch,
      listLoading,
      setIsUsingSearch,
      setSearchFieldValue,
      searchFieldValue,
      showNoResultsMessage,
    }),
    [props, isUsingSearch, listLoading, searchFieldValue, showNoResultsMessage],
  );

  return <VenturesSearchContext.Provider value={value}>{children}</VenturesSearchContext.Provider>;
};

export const useVenturesSearchContext = () => useContext(VenturesSearchContext);

export default VenturesSearchContextProvider;

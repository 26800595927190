import { useEffect } from "react";

export default function useScroll(ref) {
  useEffect(() => {
    const slider = ref.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    function mousedown(e) {
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;

      slider.classList.add("active");
    }

    function mouseleave() {
      isDown = false;

      slider.classList.remove("active");
    }

    function mouseup() {
      isDown = false;

      slider.classList.remove("active");
    }

    function mousemove(e) {
      if (!isDown) return;

      e.preventDefault();

      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;

      slider.scrollBy({ left: scrollLeft - walk });
    }

    slider.addEventListener("mousedown", mousedown);
    slider.addEventListener("mouseleave", mouseleave);
    slider.addEventListener("mouseup", mouseup);
    slider.addEventListener("mousemove", mousemove);

    return () => {
      slider.removeEventListener("mousedown", mousedown);
      slider.removeEventListener("mouseleave", mouseleave);
      slider.removeEventListener("mouseup", mouseup);
      slider.removeEventListener("mousemove", mousemove);
    };
  }, [ref]);
}

import { isEqual } from "underscore";
import store from "../../";
import { neighborhoodSlice } from "../neighborhoods/neighborhoodSlice";
import { regionSlice } from "../regions/regionSlice";
import * as requestFromServer from "./venueCrud";
import { callTypes, venueSlice } from "./venueSlice";

const getRequestFingerprint = ({ office, profiles, search, updateOthers, venueNeighborhoodIds }) => {
  const key = [office ?? "", profiles.join(","), search, String(updateOthers), venueNeighborhoodIds.join(",")].join(
    "-",
  );

  return key
    .split("")
    .map((v) => v.charCodeAt(0))
    .reduce((a, v) => (a + ((a << 7) + (a << 3))) ^ v)
    .toString(16);
};

const shouldMakeRequest = ({ fingerprint, search, filters }) => {
  const {
    venues: {
      filtering: isFiltering,
      fingerprint: currentFingerprint,
      searching,
      search: currentSearch,
      venueNeighborhoodIds: currentFilters,
    },
  } = store.getState();

  const fingerprintIsDifferent = fingerprint !== currentFingerprint;
  const filtersAreDifferent = !isEqual(filters, currentFilters);
  const isFilteringAndFiltersAreNotEmpty = isFiltering && Boolean(currentFilters.length) && Boolean(filters.length);
  const isSearching = searching;
  const isSearchingAndSearchIsNotEmpty = searching && search;
  const searchIsDifferent = currentSearch !== search;
  const isSearchingAndFiltering = Boolean(search) && Boolean(isFiltering);

  const conditions = [
    fingerprintIsDifferent,
    !isSearching || (isSearchingAndSearchIsNotEmpty && searchIsDifferent) || isSearchingAndFiltering,
    !isFiltering || isSearchingAndFiltering || (isFilteringAndFiltersAreNotEmpty && filtersAreDifferent),
  ];

  return conditions.every(Boolean);
};

export const fetchVenue = (venue) => (dispatch) => {
  const venueActions = venueSlice.actions;

  dispatch(venueActions.startCall({ callType: callTypes.action }));

  dispatch(venueActions.venueFetched({ venue: venue }));
};

export const fetchVenueSlug = (slug) => (dispatch) => {
  const venueActions = venueSlice.actions;

  dispatch(venueActions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .getVenueSlug(slug)
    .then((response) => {
      const venue = response.data.data;

      dispatch(venueActions.venueFetched({ venue: venue }));
    })
    .catch((error) => {
      dispatch(venueActions.catchError({ error, callType: callTypes.action }));

      dispatch(venueActions.venueFetched({ venue: undefined }));
    });
};

export const fetchVenues =
  ({ office = null, profiles = [], search = "", updateOthers = true, venueNeighborhoodIds = [] } = {}) =>
  (dispatch) => {
    const params = { office, profiles, search, updateOthers, venueNeighborhoodIds };
    const fingerprint = getRequestFingerprint(params);
    const filtering = venueNeighborhoodIds.length;
    const searching = Boolean(search);

    if (!shouldMakeRequest({ filtering, fingerprint, search, filters: venueNeighborhoodIds })) {
      return;
    }

    const venueActions = venueSlice.actions;
    const neighborhoodActions = neighborhoodSlice.actions;
    const regionActions = regionSlice.actions;

    dispatch(
      venueActions.startCall({
        callType: callTypes.list,
        filtering,
        fingerprint,
        search,
        searching,
        venueNeighborhoodIds,
      }),
    );

    return requestFromServer
      .getVenues({ office, profiles, search, venueNeighborhoodIds })
      .then((response) => {
        const venues = response.data.venues;
        const totalCountVenues = venues.length;

        dispatch(
          venueActions.venuesFetched({
            totalCount: totalCountVenues,
            entities: venues,
          }),
        );

        if (updateOthers) {
          const neighborhoods = response.data.venue_neighborhood;
          const totalCountNeighborhoods = neighborhoods.length;

          dispatch(
            neighborhoodActions.neighborhoodsFetched({
              totalCount: totalCountNeighborhoods,
              entities: neighborhoods,
            }),
          );

          const regions = response.data.regions;
          const totalCountRegions = regions.length;

          dispatch(
            regionActions.regionsFetched({
              totalCount: totalCountRegions,
              entities: regions,
            }),
          );
        }
      })
      .catch((error) => {
        error.clientMessage = "Not found";
        dispatch(venueActions.catchError({ error, callType: callTypes.list }));

        dispatch(venueActions.venuesFetched({ totalCount: 0, entities: [] }));
      });
  };

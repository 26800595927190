import axios from "axios";

export const GET_VENUES = `${process.env.REACT_APP_API_URL}/venues`;
export const SHOW_VENUE = `${process.env.REACT_APP_API_URL}/venue`;

export function getVenues({ office = null, profiles = [], search = "", venueNeighborhoodIds = [] } = {}) {
  let params = {};

  if (search) {
    params.q = search;
  }

  if (venueNeighborhoodIds.length) {
    params.venue_neighborhood_id = venueNeighborhoodIds;
  }

  if (profiles.length) {
    params.profile_ids = profiles;
  }

  if (office != null) {
    params.office = office ? 1 : 0;
  }

  return axios.get(GET_VENUES, {
    params,
  });
}

export function getVenueSlug(slug) {
  return axios.get(`${SHOW_VENUE}/${slug}`);
}

import { useEffect, useState } from "react";

function useMediaQuery(query) {
  const getMatches = (query) => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }

    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));
  const handleChange = () => setMatches(getMatches(query));

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    const hasAddListener = Boolean(matchMedia?.addListener);

    handleChange();

    if (hasAddListener) {
      matchMedia.addListener(handleChange);
    }

    if (!hasAddListener) {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      const hasRemoveListener = Boolean(matchMedia?.removeListener);

      if (hasRemoveListener) {
        matchMedia.removeListener(handleChange);
      }

      if (!hasRemoveListener) {
        matchMedia.removeEventListener("change", handleChange);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export default useMediaQuery;

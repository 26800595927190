import useFullscreen from "hooks/useFullscreen";
import { createContext, useContext, useMemo } from "react";

const GlobalContext = createContext({});
GlobalContext.displayName = "GlobalContext";

const GlobalContextProvider = ({ children }) => {
  const { isFullScreen, toggleFullScreen } = useFullscreen();

  const value = useMemo(
    () => ({
      isFullScreen,
      toggleFullScreen,
    }),
    [isFullScreen, toggleFullScreen],
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = () => useContext(GlobalContext);

export default GlobalContextProvider;

import VerticalButton from "Components/VerticalButton";
import VerticalLink from "Components/VerticalLink";

const VenturesLink = () => {
  return (
    <VerticalLink className="right-0" to="/empreendimentos">
      <VerticalButton className="bg-[#245086] bg-[url('./Media/invista_agora.png')] shadow-[10px_20px_10px_30px_rgba(0,0,0,0.3)]" />
    </VerticalLink>
  );
};

export default VenturesLink;

const waitForGlobalVar = (expectedVar, cb = () => {}) => {
  setTimeout(() => {
    if (window[expectedVar]) {
      return cb(window[expectedVar]);
    }

    waitForGlobalVar(expectedVar, cb);
  }, 300);
};

export default waitForGlobalVar;

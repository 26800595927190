import VerticalButton from "Components/VerticalButton";
import VerticalLink from "Components/VerticalLink";

const AboutUsLink = () => {
  return (
    <VerticalLink className="left-0" to="/sobre-nos">
      <VerticalButton className="bg-[#B80800] bg-[url('./Media/sobre_vitacon.png')] shadow-[20px_0_20px_0_rgba(0,0,0,0.3)]" />
    </VerticalLink>
  );
};

export default AboutUsLink;

import clsx from "clsx";

const Pulse = ({ className }) => {
  return (
    <span
      className={clsx(
        "pulse pointer-events-none absolute left-[calc(50%-12px)] top-[5px] m-auto h-[22px] w-[22px] animate-pulse cursor-pointer rounded-full shadow-[0_0_0_0_rgba(255,255,255,0.4)]",
        className,
      )}
    />
  );
};

export default Pulse;

import React, { useState, useEffect } from "react";
import { Routes } from "./Routes";
import EnterFullscreenMode from "./Pages/EnterFullscreenMode";

const Vitacon = () => {
  const [shouldShowEnterFullscreen, setShouldShowEnterFullscreen] = useState(false);

  useEffect(() => {
    setShouldShowEnterFullscreen(true);
  }, []);

  return (
    <div className="App">
      <div className="AppContent">
        <Routes />
      </div>

      {shouldShowEnterFullscreen && <EnterFullscreenMode onClose={() => setShouldShowEnterFullscreen(false)} />}
    </div>
  );
};

export default Vitacon;

import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MemoryRouter as Router, Route, Switch, useParams } from "react-router-dom";
import VenueMenu from "../Components/VenueMenu/VenueMenu";
import Fullscreen from "../Pages/Fullscreen";
import { fetchVenueSlug } from "../redux/reducers/venues/venueActions";
import Applications from "./Applications";
import Books from "./Books";
import Gallery from "./Gallery";
import Implantation from "./Implantation";
import Location from "./Location";
import TechnicalData from "./TechnicalData";
import Units from "./Units";
import Videos from "./Videos";

export default function Venue() {
  const { venues } = useSelector(
    (state) => ({
      venues: state.venues,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const [venue, setVenue] = useState({});
  const [shouldShowMainBg, setShouldShowMainBg] = useState(true);
  const [colors, setColors] = useState({
    active_font_color: null,
    active_primary_color: null,
    active_secondary_color: null,
    address_background_color: null,
    address_font_color: null,
    background_color: null,
    font_color: null,
    logo_background_color: null,
    primary_color: null,
    secondary_color: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [modalScope, setModalScope] = useState("");
  const [hideBlack, setHideBlack] = useState(false);

  const { slug } = useParams();

  useEffect(() => {
    if (venues.venue) {
      setVenue(venues.venue);
      setColors(venues.venue.colors);
    }

    if (!venues.venue && !venues.actionsLoading) {
      dispatch(fetchVenueSlug(slug));
    }
    setTimeout(() => {
      setHideBlack(true);
    }, 700);
  }, [dispatch, slug, venues]);

  function handleChangePage(boolean = false) {
    setShouldShowMainBg(boolean);
  }

  function onOpenModal(current_id, elements, scope) {
    const modalData = {
      current_id: current_id,
      elements: elements,
    };
    setModalContent(modalData);
    setModalScope(scope);
    setIsModalOpen(true);
  }

  function handleCloseModal(el) {
    setIsModalOpen(el);
  }

  const getApplications = (applicationType) =>
    venue.menu_defaults.find(({ name }) => name === applicationType)?.applications ?? [];

  return (
    <>
      <div className={`loadingVenuePageOut ${hideBlack ? "hideBlack" : null}`}></div>
      <div className="venue-content_wrapper" style={{ backgroundColor: colors.background_color }}>
        {!isModalOpen ? (
          <section
            className="venue-content_logo w-[14vw] lg:w-[10%]"
            style={{ backgroundColor: colors.logo_background_color }}
            onClick={() => handleChangePage(true)}
          >
            <img src={venue.logo_sm_image} alt="" />
            <p
              className="venue-content_logo--subtitle"
              style={{
                backgroundColor: colors.address_background_color,
                color: colors.address_font_color,
              }}
            >
              {venue.address}
            </p>
          </section>
        ) : null}
        <Router>
          <section
            className="venue-content_item image-bg"
            style={{
              backgroundImage: shouldShowMainBg ? `url(${venue.campaign_image})` : "",
            }}
          >
            {!shouldShowMainBg && (
              <Switch>
                <Route exact path="/imagens">
                  <div className="venue-content_item-content">
                    <Gallery venue={venue} onOpenGalleryModal={onOpenModal} />
                  </div>
                </Route>
                <Route exact path="/ficha-tecnica">
                  <div className="venue-content_item-content">
                    <TechnicalData venue={venue} />
                  </div>
                </Route>
                <Route exact path="/videos">
                  <div className="venue-content_item-content">
                    <Videos venue={venue} onOpenVideosModal={onOpenModal} />
                  </div>
                </Route>
                <Route exact path="/localizacao">
                  <div className="venue-content_item-content">
                    <Location venue={venue} />
                  </div>
                </Route>
                <Route exact path="/unidades">
                  <div className="venue-content_item-content">
                    <Units venue={venue} onOpenUnitsModal={onOpenModal} />
                  </div>
                </Route>
                <Route exact path="/book">
                  <div className="venue-content_item-content">
                    <Books venue={venue} />
                  </div>
                </Route>
                <Route exact path="/implantacao">
                  <div className="venue-content_item-content">
                    <Implantation venue={venue} />
                  </div>
                </Route>
                <Route exact path="/tour-360">
                  <div className="venue-content_item-content">
                    <Applications items={getApplications("tour")} onOpenApplication={onOpenModal} venue={venue} />
                  </div>
                </Route>
              </Switch>
            )}
          </section>
          <div className="venue-content_menu">
            {venue.menu_defaults && (
              <VenueMenu
                mainColor={[colors.primary_color, colors.secondary_color]}
                activeColor={[colors.active_primary_color, colors.active_secondary_color]}
                fontColor={colors.font_color}
                activeFontColor={colors.active_font_color}
                borderColor={colors.logo_background_color}
                active={"images"}
                itens={venue.menu_defaults.map((menu) => menu["name"])}
                onChangePage={handleChangePage}
              />
            )}
          </div>
        </Router>
      </div>
      {isModalOpen && (
        <Fullscreen venue={venue} content={modalContent} onCloseModal={handleCloseModal} scope={modalScope} />
      )}
    </>
  );
}

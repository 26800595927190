import PinMap from "Components/PinMap/PinMap";
import { NoResults } from "Components/VenturesSearch";
import { useVenturesListContext } from "../contexts/VenturesListContext";
import { useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import ThumbButton from "./ThumbButton";
import useMediaQuery from "hooks/useMediaQuery";
import { useMemo } from "react";

const thumbButtonSize = 42;
const widthFactor = 1.275;

const Ventures = () => {
  const {
    venues: { entities },
  } = useVenturesListContext();

  const thumbColor = "#48697C";
  const thumbBgColor = "#6AA8CC";
  const scrollRef = useRef(null);
  const listRef = useRef(null);
  const thumbRef = useRef(null);
  const [itemSize, setItemSize] = useState(200);
  const [isDragging, setIsDragging] = useState(false);
  const [isSwipping, setIsSwipping] = useState(false);
  const [thumbSize, setThumbSize] = useState(thumbButtonSize);
  const [initialScrollLeft, setInitialScrollLeft] = useState(0);
  const [canShow, setCanShow] = useState(false);
  const [scrollStartPosition, setScrollStartPosition] = useState(null);
  const [thumbButtonSelfPosition, setThumbButtonSelfPosition] = useState("translate(0, 50%)");
  const isTablet = useMediaQuery("(max-width: 1023px)");
  const visibleItems = useMemo(() => (isTablet ? 2 : 4), [isTablet]);
  const numberOfRows = useMemo(() => (isTablet ? 1 : 2), [isTablet]);

  const resetScrollLeft = () => (scrollRef.current.scrollLeft = 0);
  const setListWidth = ($listElement, listWidth) => $listElement.style.setProperty("width", `${listWidth}px`);
  const setThumbPosition = (thumbPosition) => thumbRef.current?.style.setProperty("left", `${thumbPosition}px`);

  const onScroll = useCallback(
    (event) => {
      const {
        target: { clientWidth: containerWidth, scrollLeft: scrollAmount },
      } = event;

      const { scrollWidth: contentWidth } = listRef.current?.parentElement;
      const thumbXPosition = (+scrollAmount / +contentWidth) * containerWidth;
      const thumbScrollDifferential = contentWidth - thumbSize;
      const thumbPosition = Math.min(thumbXPosition, thumbScrollDifferential);

      setThumbPosition(thumbPosition);
    },
    [thumbSize],
  );

  const onMouseDown = (event, swipe = false) => {
    let clientX = 0;

    if (event.type === "mousedown") {
      event.preventDefault();
      event.stopPropagation();

      clientX = event.clientX;
    }

    if (event.type === "touchstart") {
      clientX = event.touches[0].clientX;
    }

    setScrollStartPosition(clientX);

    if (scrollRef.current) {
      setInitialScrollLeft(scrollRef.current.scrollLeft);
    }

    setIsDragging(true);
    setIsSwipping(swipe);
  };

  const onMouseMove = useCallback(
    (event) => {
      let clientX = 0;

      if (event.type === "mousemove") {
        event.preventDefault();
        event.stopPropagation();

        clientX = event.clientX;
      }

      if (event.type === "touchmove") {
        clientX = event.touches[0].clientX;
      }

      if (isDragging) {
        const { scrollWidth: contentScrollWidth, offsetWidth: contentOffsetWidth } = scrollRef.current;
        const deltaX = (clientX - scrollStartPosition) * (contentOffsetWidth / thumbSize);
        let scrollAmount = initialScrollLeft + deltaX;

        if (isSwipping) {
          scrollAmount = initialScrollLeft - deltaX;
        }

        const newScrollLeft = Math.min(scrollAmount, contentScrollWidth - contentOffsetWidth);

        scrollRef.current.scrollLeft = newScrollLeft;

        onScroll({ target: scrollRef.current });
      }
    },
    [initialScrollLeft, isDragging, isSwipping, onScroll, scrollStartPosition, thumbSize],
  );

  const onMouseUp = useCallback(
    (event) => {
      if (event.type === "mouseup") {
        event.preventDefault();
        event.stopPropagation();
      }

      if (isDragging) {
        setIsDragging(false);
      }

      if (isSwipping) {
        setIsSwipping(false);
      }
    },
    [isDragging, isSwipping],
  );

  const handleListSize = useCallback(
    ($listElement) => {
      const itemsCount = entities.length;
      const hasMoreThanVisibleItems = itemsCount > visibleItems;

      if (hasMoreThanVisibleItems) {
        const calcScrollContainerWidth = $listElement.parentElement.clientWidth;
        const calcItemMarginRight = (calcScrollContainerWidth * visibleItems) / 100;
        const calcItemSize = calcScrollContainerWidth / visibleItems - calcItemMarginRight;
        const calcListSize = calcItemSize * widthFactor * (itemsCount / numberOfRows);
        const calcThumbSize = Math.max((calcScrollContainerWidth / calcListSize) * calcScrollContainerWidth, 80);
        const thumbButtonTranslateX = (calcThumbSize - thumbButtonSize) / 2;
        const thumbButtonTranslate = `translate(${thumbButtonTranslateX}px, 50%)`;

        resetScrollLeft();
        setListWidth($listElement, calcListSize);
        setItemSize(calcItemSize);
        setThumbSize(calcThumbSize);
        setThumbButtonSelfPosition(thumbButtonTranslate);
      }
    },
    [entities.length, numberOfRows, visibleItems],
  );

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.style.setProperty("--scroller-scrollbar-track", thumbBgColor);
      scrollRef.current.style.setProperty("--scroller-scrollbar", thumbColor);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchmove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("touchend", onMouseUp);
    document.addEventListener("mouseleave", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchmove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("touchend", onMouseUp);
      document.removeEventListener("mouseleave", onMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  useEffect(() => {
    const shouldHandleListSize = Boolean(entities.length && listRef.current && scrollRef.current);

    if (shouldHandleListSize) {
      const $listElement = listRef.current;
      const resizeObserver = new ResizeObserver(() => handleListSize($listElement));

      resizeObserver.observe($listElement);
      handleListSize(listRef.current);

      if (!canShow) {
        setCanShow(true);
      }

      return () => resizeObserver.unobserve($listElement);
    }
  }, [canShow, entities.length, handleListSize]);

  return (
    <>
      <div
        className="ta-scroll__container relative ml-[6vw] h-[80%] w-[calc(100%-6vw)] overflow-x-auto overflow-y-clip opacity-0 transition-opacity delay-75 duration-500 ease-in-out"
        onMouseDown={(event) => onMouseDown(event, true)}
        onScroll={onScroll}
        ref={scrollRef}
        style={{ opacity: canShow ? 1 : 0 }}
      >
        <div className="listPins mx-auto my-0 mt-10 flex h-full flex-wrap pt-10 pb-4" ref={listRef}>
          <NoResults />
          {entities.map((venue, i) => (
            <PinMap key={i} isList={true} isOpened={true} style={{ flexBasis: `${itemSize}px` }} venue={venue} />
          ))}
        </div>
      </div>
      <ThumbButton
        bgColor={thumbBgColor}
        className="opacity-0 transition-opacity delay-75 duration-500 ease-in-out"
        color={thumbColor}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        ref={thumbRef}
        style={{
          cursor: isDragging ? "grabbing" : "grab",
          opacity: canShow ? 1 : 0,
          transform: thumbButtonSelfPosition,
        }}
      />
    </>
  );
};

export default Ventures;

import { contexts } from "App.contexts";
import DoubleButton from "Components/DoubleButton";
import { useSetStateContext, useStateContext } from "react-context-x";

const ListMapButtons = () => {
  const activeList = useStateContext(contexts, "activeList");
  const setActiveList = useSetStateContext(contexts, "activeList");
  const onClickFirstButton = () => setActiveList(true);
  const onClickSecondButton = () => setActiveList(false);

  return (
    <DoubleButton
      activeButton={activeList ? "first" : "second"}
      firstButtonLabel="LISTA"
      onClickFirstButton={onClickFirstButton}
      onClickSecondButton={onClickSecondButton}
      secondButtonLabel="MAPA"
    />
  );
};

export default ListMapButtons;

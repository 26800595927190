import clsx from "clsx";
import Pulse from "Components/Pulse/Pulse";
import Scroller from "Components/Scroller/Scroller";
import { icons } from "Components/VenueMenu/icons";
import { useRef, useState } from "react";
import useScroll from "Utils/useScroll";

export default function Applications({ items, onOpenApplication, venue }) {
  const applicationsRef = useRef(null);
  const scrollableRef = useRef(null);
  const [scrollUpdatedPos, setScrollUpdatedPos] = useState(0);
  const hasThreeOrMoreApplications = items.length >= 3;
  const hasOnlyOneApplication = items.length === 1;
  const applicationsClasses = hasThreeOrMoreApplications ? "overshadow" : "";
  const applicationsContentClasses = hasOnlyOneApplication ? "single-video" : "";
  const applicationClasses = hasOnlyOneApplication ? "single" : "";

  const {
    colors: { active_font_color, active_primary_color, background_color, font_color, primary_color },
  } = venue;

  const openApplication = ({ id }) => {
    onOpenApplication(id, items, "applications");
  };

  useScroll(scrollableRef);

  return (
    <section className={clsx("venue-gallery_wrapper", applicationsClasses)} ref={applicationsRef}>
      <div
        className={clsx("venue-gallery_content video-content", applicationsContentClasses)}
        ref={scrollableRef}
        onScroll={(e) => setScrollUpdatedPos(e.target.scrollLeft)}
      >
        {items.map(({ displayName, id, img }) => {
          return (
            <div className={clsx("venue-gallery_content-item videos", applicationClasses)} key={id}>
              <div
                className="video-item_wrapper bg-cover bg-no-repeat"
                onClick={() => openApplication({ id })}
                style={img ? { backgroundImage: `url(${img})` } : null}
              >
                <div className="overlay opacity-80" style={{ backgroundColor: primary_color }} />
                <Pulse />
                <div className="player-button">{icons?.["tourBig"]?.icon?.(font_color)}</div>
              </div>
              <p>{displayName}</p>
            </div>
          );
        })}
      </div>
      <Scroller
        scrollRef={scrollableRef}
        wrapperRef={applicationsRef}
        scrollUpdatedPos={scrollUpdatedPos}
        fabColor={active_primary_color}
        fabSvgColor={active_font_color}
        scrollbarColor={background_color}
        scrollbarTrackColor={primary_color}
        style={{
          marginTop: "-22px",
        }}
      />
    </section>
  );
}

import clsx from "clsx";
import { forwardRef } from "react";
import { icons } from "Components/VenueMenu/icons";
import SquareButton from "Components/SquareButton/SquareButton";

const Icon = ({ color = "#fff", direction }) => <>{icons?.[`arrow_${direction}`]?.icon?.(color)}</>;

const Button = forwardRef((props, ref) => <SquareButton ref={ref} {...props} />);

const ArrowButton = forwardRef(({ iconColor, direction, ...props }, ref) => (
  <Button className={direction} ref={ref} {...props}>
    <Icon color={iconColor} direction={direction} />
  </Button>
));

export const Left = forwardRef(({ className, ...props }, ref) => {
  return (
    <ArrowButton
      className={clsx("[&_svg]:relative [&_svg]:-left-[2px] [&_svg]:w-3 [&_svg]:xl:w-4", className)}
      direction="left"
      ref={ref}
      {...props}
    />
  );
});

export const Right = forwardRef(({ className, ...props }, ref) => {
  return (
    <ArrowButton
      className={clsx("[&_svg]:relative [&_svg]:-right-[2px] [&_svg]:w-3 [&_svg]:xl:w-4", className)}
      direction="right"
      ref={ref}
      {...props}
    />
  );
});

import clsx from "clsx";
import React, { useState } from "react";
import Button from "./components/Button";

const DoubleButton = ({
  activeButton,
  className,
  firstButtonLabel,
  onClickFirstButton,
  onClickSecondButton,
  secondButtonLabel,
}) => {
  const getButtonState = (button) => (activeButton === button ? "active" : "");
  const [firstActive, setFirstActive] = useState(getButtonState("first"));
  const [secondActive, setSecondActive] = useState(getButtonState("second"));

  const handleClickFirst = () => {
    if (secondActive !== "") {
      setSecondActive("");
      setFirstActive("active");

      if (onClickFirstButton) {
        onClickFirstButton();
      }
    }
  };

  const handleClickSecond = () => {
    if (firstActive !== "") {
      setSecondActive("active");
      setFirstActive("");

      if (onClickSecondButton) {
        onClickSecondButton();
      }
    }
  };

  return (
    <div
      className={clsx(
        "absolute -top-[200px] z-[2] flex h-8 w-52 animate-showDoubleBtn rounded border border-solid border-[#2b3b4d] bg-[linear-gradient(3deg,_#1b252f_0%,#23303e_100%)] lg:h-[4.35%] lg:w-1/4",
        className,
      )}
    >
      <Button className={firstActive} onClick={handleClickFirst}>
        {firstButtonLabel}
      </Button>
      <Button className={secondActive} onClick={handleClickSecond}>
        {secondButtonLabel}
      </Button>
    </div>
  );
};

export default DoubleButton;

import React from "react";
import Carousel from "./components/Carousel";
import Item from "./components/Item";
import { useInstitutionalCarouselContext } from "./contexts/InstitutionalCarouselContext";

const InstitutionalCarousel = () => {
  const { menu } = useInstitutionalCarouselContext();

  return (
    <Carousel>
      {menu.map((itemProps) => (
        <Item key={itemProps.id} {...itemProps} />
      ))}
    </Carousel>
  );
};

export default React.memo(InstitutionalCarousel);

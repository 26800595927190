import { createContext, useContext, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { omit } from "underscore";

const VenturesListContext = createContext({});
VenturesListContext.displayName = "VenturesListContext";

const VenturesListContextProvider = (props) => {
  const { children } = props;
  const { venues } = useSelector((state) => state, shallowEqual);
  const propsWithoutChildren = omit(props, "children");

  const value = useMemo(
    () => ({
      ...propsWithoutChildren,
      venues,
    }),
    [propsWithoutChildren, venues],
  );

  return <VenturesListContext.Provider value={value}>{children}</VenturesListContext.Provider>;
};

export const useVenturesListContext = () => useContext(VenturesListContext);

export default VenturesListContextProvider;

import { useState, useEffect } from "react";
import Mapplic from "../Components/Mapplic/Mapplic";

export default function Location({ venue }) {
  const [mapImageDimensions, setMapImageDimensions] = useState(null);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    const img = new Image();

    img.addEventListener("load", function () {
      setMapImageDimensions({ height: this.naturalHeight, width: this.naturalWidth });
    });

    img.src = venue.map_image;
  }, [venue]);

  useEffect(() => {
    const locations = [];

    venue.places.forEach((place) => {
      locations.push({
        category: venue.id,
        fill: "#007CBA",
        id: place.id,
        level: venue.id,
        pin: "voyager-location pin-pulse pin-lg",
        title: place.name,
        x: place.position_x,
        y: place.position_y,
        zoom: "2",
      });
    });

    setLevels([
      {
        hide: true,
        id: venue.id,
        locations: locations,
        map: venue.map_image,
        title: venue.name,
      },
    ]);

    setCategories([
      {
        id: venue.id,
        title: venue.name,
      },
    ]);
  }, [venue]);

  return (
    <Mapplic
      activeFontColor={venue.colors.active_font_color}
      config={{
        bgcolor: venue.colors.primary_color,
        bgcolor2: venue.colors.active_primary_color,
        headingcolor: venue.colors.font_color,
        source: {
          categories,
          levels,
        },
        textcolor: venue.colors.font_color,
      }}
      fontColor={venue.colors.font_color}
      mainColor={[venue.colors.primary_color, venue.colors.secondary_color]}
      mapImageDimensions={mapImageDimensions}
    />
  );
}

import React, { createContext, useContext, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

const InstitutionalCarouselContext = createContext({});
InstitutionalCarouselContext.displayName = "InstitutionalCarouselContext";

const InstitutionalCarouselContextProvider = (props) => {
  const { children } = props;
  const { menu } = useSelector(({ institutional: { menu } }) => ({ menu }), shallowEqual);

  const responsive = useMemo(
    () => ({
      all: {
        breakpoint: { max: 99999, min: 0 },
        items: 1,
      },
    }),
    [],
  );

  const value = useMemo(
    () => ({
      ...props,
      menu,
      responsive,
    }),
    [props, menu, responsive],
  );

  return <InstitutionalCarouselContext.Provider value={value}>{children}</InstitutionalCarouselContext.Provider>;
};

export const useInstitutionalCarouselContext = () => useContext(InstitutionalCarouselContext);

export default InstitutionalCarouselContextProvider;

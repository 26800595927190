import { useState, useEffect } from "react";
import Mapplic from "../Components/Mapplic/Mapplic";

export default function Implantation({ venue }) {
  const [mapImageDimensions, setMapImageDimensions] = useState(null);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    const img = new Image();

    img.addEventListener("load", function () {
      setMapImageDimensions({ height: this.naturalHeight, width: this.naturalWidth });
      console.log({ height: this.naturalHeight, width: this.naturalWidth });
    });

    img.src = venue.map_image;
  }, [venue]);

  useEffect(() => {
    const levels = [];
    const categories = [];

    venue.implantations.forEach((implantation) => {
      const locations = [];

      implantation.implantation_pavements.forEach((implantation_pavement) => {
        locations.push({
          id: implantation_pavement.id,
          title: implantation_pavement.name,
          category: implantation.id,
          level: implantation.id,
          x: implantation_pavement.position_x,
          y: implantation_pavement.position_y,
          zoom: implantation_pavement.zoom,
          pin: "voyager-location pin-pulse pin-md",
          fill: venue.colors.primary_color,
        });
      });

      levels.push({
        id: implantation.id,
        title: implantation.name,
        map: implantation.map_image,
        locations: locations,
      });

      categories.push({
        id: implantation.id,
        title: implantation.name,
      });
    });

    setCategories(categories);
    setLevels(levels);
  }, [venue]);

  return (
    <Mapplic
      activeFontColor={venue.colors.active_font_color}
      config={{
        bgcolor: venue.colors.primary_color,
        bgcolor2: venue.colors.active_primary_color,
        headingcolor: venue.colors.font_color,
        maxscale: 1,
        source: {
          categories,
          levels,
        },
        textcolor: venue.colors.font_color,
      }}
      fontColor={venue.colors.font_color}
      mainColor={[venue.colors.primary_color, venue.colors.secondary_color]}
      mapImageDimensions={mapImageDimensions}
    />
  );
}

import Container from "./Container";

const LargerScreenRequirement = () => {
  return (
    <Container className="larger-screen-requirement md:hidden">
      Esta apresentação interativa é melhor visualizada em <b>telas maiores</b>. Acesse este mesmo endereço através de
      um <b>tablet</b>, <b>notebook</b>, <b>computador</b> ou <b>TV touch screen</b>.
    </Container>
  );
};

export default LargerScreenRequirement;

import { ViewerContainer } from "./Styles";
import RViewerJS from "viewerjs-react";

// eslint-disable-next-line no-empty-pattern
export default function Viewer({ children, fontColor, mainColor, options, secondaryColor, zoom }) {
  return (
    <ViewerContainer
      className="map-container"
      fontColor={fontColor}
      mainColor={mainColor}
      secondaryColor={secondaryColor}
    >
      <RViewerJS
        options={{
          ...{
            backdrop: false,
            button: false,
            inline: true,
            navbar: false,
            title: false,
            toolbar: {
              zoomIn: 1,
              zoomOut: 1,
              oneToOne: 0,
              reset: 0,
              prev: 0,
              play: {
                show: 0,
                size: "large",
              },
              next: 0,
              rotateLeft: 0,
              rotateRight: 0,
              flipHorizontal: 0,
              flipVertical: 0,
            },
          },
          ...options,
        }}
      >
        {children}
      </RViewerJS>
    </ViewerContainer>
  );
}

import ScreenRequirements from "Components/ScreenRequirements";
import GlobalContextProvider from "contexts/GlobalContext";
import { Providers } from "react-context-x";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { contexts } from "./App.contexts";
import "./App.scss";
import Vitacon from "./Vitacon";

const App = ({ store }) => {
  return (
    <GlobalContextProvider>
      <Providers contexts={contexts}>
        <Provider store={store}>
          <BrowserRouter>
            <ScreenRequirements>
              <Vitacon />
            </ScreenRequirements>
          </BrowserRouter>
        </Provider>
      </Providers>
    </GlobalContextProvider>
  );
};

export default App;

import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import Pulse from "Components/Pulse";
import Scroller from "Components/Scroller/Scroller";
import useScroll from "Utils/useScroll";

export default function Gallery({ venue, onOpenGalleryModal }) {
  const scrollableRef = useRef(null);
  const wrapperRef = useRef(null);
  const [scrollUpdatedPos, setScrollUpdatedPos] = useState(0);
  const [imgLoaded, setImgLoaded] = useState([]);
  useScroll(scrollableRef);

  useEffect(() => {
    venue.venue_images.map((img) => {
      setImgLoaded((imgLoaded[img.id] = false));
      const image = new Image();
      image.src = img.url;
      image.onload = () => {
        setImgLoaded((imgLoaded[img.id] = true));
      };

      return img;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venue]);

  function handleToggleFullscreen(current_id) {
    onOpenGalleryModal(current_id, venue.venue_images, "gallery");
  }

  return (
    <>
      <section
        className={clsx("venue-gallery_wrapper", venue.venue_images.length >= 5 ? "overshadow" : "")}
        ref={wrapperRef}
      >
        <div
          className="venue-gallery_content gallery-content"
          ref={scrollableRef}
          onScroll={(e) => setScrollUpdatedPos(e.target.scrollLeft)}
        >
          {venue.venue_images.map((image) =>
            imgLoaded[image.id] ? (
              <div className="venue-gallery_content-item gallery">
                <p>loading</p>
              </div>
            ) : (
              <div
                className="venue-gallery_content-item gallery"
                key={image.id}
                onClick={() => handleToggleFullscreen(image.id)}
              >
                <Pulse />
                <img src={image.url} alt={image.legend} />
                <p>{image.legend}</p>
              </div>
            ),
          )}
        </div>
        <Scroller
          scrollRef={scrollableRef}
          wrapperRef={wrapperRef}
          scrollUpdatedPos={scrollUpdatedPos}
          fabColor={venue.colors.active_primary_color}
          fabSvgColor={venue.colors.active_font_color}
          scrollbarColor={venue.colors.background_color}
          scrollbarTrackColor={venue.colors.primary_color}
          style={{
            marginTop: "-22px",
          }}
        />
      </section>
    </>
  );
}

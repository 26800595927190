import BackButton from "./components/BackButton";
import SearchBox from "./components/SearchBox";
import SearchButton from "./components/SearchButton";
import SearchField from "./components/SearchField";
import SearchForm from "./components/SearchForm";

const VenturesSearch = () => {
  return (
    <SearchBox>
      <BackButton />
      <SearchForm>
        <SearchField />
        <SearchButton />
      </SearchForm>
    </SearchBox>
  );
};

export default VenturesSearch;

import styled from "styled-components";

export const ViewerContainer = styled.div(({ fontColor, mainColor, secondaryColor }) => ({
  "& .viewer-toolbar": {
    bottom: 0,
    position: "fixed",
    right: 0,
    "& ul": {
      display: "flex",
      flexDirection: "column",
      margin: 0,
      padding: 0,
    },
    "& li": {
      borderRadius: 0,
      fontSize: "20px",
      height: "60px",
      margin: 0,
      width: "60px",
      "&:before": {
        background: "none",
        color: fontColor,
        fontSize: "20px",
        lineHeight: "4.4rem",
        margin: "0 auto",
      },
    },
    "& .viewer-zoom-in": {
      backgroundColor: mainColor,
      "&:before": {
        content: '"+"',
      },
    },
    "& .viewer-zoom-out": {
      backgroundColor: secondaryColor,
      "&:before": {
        content: '"-"',
      },
    },
  },
}));

import { useEffect } from "react";
import waitForGlobalVar from "../../Utils/waitForGlobalVar";
import { MapplicContainer, MapplicInstance } from "./Styles";

export default function Mapplic({ activeFontColor, config, fontColor, mainColor, mapImageDimensions }) {
  useEffect(() => {
    if (mapImageDimensions) {
      waitForGlobalVar("$", ($) => {
        const map = $("#mapplic").mapplic({
          ...config,
          ...{
            customcss: ".mapplic-filtered svg [id^=landmark] > * {opacity: 1 !important;}",
            deeplinking: false,
            height: "100%",
            mapfill: true,
            maxscale: 3,
            mousewheel: true,
            search: false,
            sidebar: true,
            source: {
              ...config.source,
              ...{ mapwidth: mapImageDimensions.width, mapheight: mapImageDimensions.height, defaultstyle: "light" },
            },
            zoommargin: 0,
            zoomoutclose: true,
          },
        });

        map.on("locationopen", (e, s) => {
          s.list
            .parents(".mapplic-sidebar")
            .first()
            .animate(
              {
                scrollTop: $(`[data-location="${s.list.data("location")}"]`).offset().top,
              },
              800
            );
        });
      });
    }
  }, [config, mapImageDimensions]);

  return (
    <MapplicContainer className="map-container">
      <MapplicInstance
        activeFontColor={activeFontColor}
        fontColor={fontColor}
        id="mapplic"
        key="12"
        mainColor={mainColor}
      />
    </MapplicContainer>
  );
}

import * as React from 'react';
import { Icon, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import { Button, ViewerContainer, PreviousButtonContainer, NextButtonContainer, Thumbnail, ThumbnailsContainer, ZoomButtonContainer } from './Styles'

export function SlidePresentationExample ({ colors, fileUrl }) {
    const { primary_color, secondary_color, font_color } = colors;
    const thumbnailPluginInstance = thumbnailPlugin({ thumbnailWidth: 200 });
    const { Thumbnails } = thumbnailPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();

    const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;
    const { ZoomIn, ZoomOut } = zoomPluginInstance;
    const { PageFit } = SpecialZoomLevel;

    const primaryButton = {
        background: primary_color,
        font: font_color,
    };

    const secondaryButton = {
        background: secondary_color,
        font: font_color,
    };

    return (
        <ViewerContainer className="rpv-core__viewer">
            <PreviousButtonContainer>
                <GoToPreviousPage>
                    {({ onClick }) => (
                        <Button {...primaryButton} onClick={onClick}>
                            <Icon size={16}>
                                <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                            </Icon>
                        </Button>
                    )}
                </GoToPreviousPage>
            </PreviousButtonContainer>

            <NextButtonContainer>
                <GoToNextPage>
                    {({ onClick }) => (
                        <Button {...primaryButton} onClick={onClick}>
                            <Icon size={16}>
                                <path d="M5.651,23.5,18.227,12.374a.5.5,0,0,0,0-.748L5.651.5" />
                            </Icon>
                        </Button>
                    )}
                </GoToNextPage>
            </NextButtonContainer>

            <ZoomButtonContainer>
                <ZoomIn>
                    {({ onClick }) => (<Button {...primaryButton} onClick={onClick}>+</Button>)}
                </ZoomIn>
                <ZoomOut>
                    {({ onClick }) => (<Button {...secondaryButton} onClick={onClick}>-</Button>)}
                </ZoomOut>
            </ZoomButtonContainer>

            <ThumbnailsContainer>
                <Thumbnails renderThumbnailItem={({ pageIndex, currentPage, onJumpToPage, renderPageThumbnail }) => (
                    <Thumbnail color={primary_color} key={pageIndex}>
                        {pageIndex === currentPage && <div className="opacity-layer"></div>}
                        <div onClick={onJumpToPage}>
                            {renderPageThumbnail}
                        </div>
                    </Thumbnail>
                )} />
            </ThumbnailsContainer>

            <Viewer
                fileUrl={fileUrl}
                plugins={[thumbnailPluginInstance, pageNavigationPluginInstance, zoomPluginInstance]}
                defaultScale={PageFit}
            />
        </ViewerContainer>
    );
};
export default function TechnicalData({ venue }) {

  return (
    <section className="venue-techdata_wrapper">
      <div
        className="venue-techdata_content"
        style={{ backgroundImage: `url(${venue.facade_image})` }}
      ></div>
      <div className="venue-techdata_content table">
        <h2>ficha técnica</h2>
        <table>
          <tbody>
            {venue.venue_datasheets.map((el) => (
              <tr key={el.id}>
                <td>{el.item}:</td>
                <td>{el.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

import clsx from "clsx";
import Pulse from "Components/Pulse/Pulse";

const VerticalButton = ({ className }) => {
  return (
    <div
      className={clsx(
        "flex h-full w-full items-center justify-center bg-[length:33%] bg-center bg-no-repeat text-white",
        className,
      )}
    >
      <Pulse className="!static" />
    </div>
  );
};

export default VerticalButton;

import InstitutionalCarousel from "Components/InstitutionalCarousel";
import InstitutionalCarouselContextProvider from "Components/InstitutionalCarousel/contexts/InstitutionalCarouselContext";
import InstitutionalPageContainer from "Components/InstitutionalPageContainer";

const Institutional = () => {
  return (
    <InstitutionalPageContainer>
      <InstitutionalCarouselContextProvider>
        <InstitutionalCarousel />
      </InstitutionalCarouselContextProvider>
    </InstitutionalPageContainer>
  );
};

export default Institutional;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actionsLoading: false,
  entities: [],
  entityForEdit: undefined,
  fingerprint: undefined,
  filtering: false,
  lastError: null,
  listLoading: false,
  search: "",
  searching: false,
  totalCount: 0,
  venue: undefined,
  venueNeighborhoodIds: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const venueSlice = createSlice({
  name: "venues",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    clearSearch: (state) => {
      state.search = "";
      state.searching = false;
    },
    startCall: (
      state,
      {
        payload: {
          callType,
          filtering = false,
          fingerprint,
          search = "",
          searching = false,
          venueNeighborhoodIds = [],
        },
      },
    ) => {
      state.error = null;

      if (callType === callTypes.list) {
        state.listLoading = true;
        state.filtering = filtering;
        state.fingerprint = fingerprint;
        state.search = search.trim();
        state.searching = searching;
        state.venueNeighborhoodIds = venueNeighborhoodIds;
      } else {
        state.actionsLoading = true;
      }
    },
    venueFetched: (state, action) => {
      state.actionsLoading = false;
      state.venue = action.payload.venue;
      state.error = null;
    },
    venuesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;

      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
  },
});

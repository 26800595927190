import { useVenturesSearchContext } from "../contexts/VenturesSearchContext";
import useVenturesSearch from "../hooks/useVenturesSearch";

const SearchForm = ({ children }) => {
  const { isUsingSearch, listLoading, searchFieldValue, setIsUsingSearch } = useVenturesSearchContext();
  const { searchVentures } = useVenturesSearch();

  const handleSubmit = (event) => {
    event.preventDefault();

    const canSearch = searchFieldValue && !listLoading;

    if (canSearch) {
      searchVentures(searchFieldValue);

      if (!isUsingSearch) {
        setIsUsingSearch(!isUsingSearch);
      }
    }
  };

  return (
    <div className="c-ventures-search shadow-xl">
      <form onSubmit={handleSubmit}>{children}</form>
    </div>
  );
};

export default SearchForm;

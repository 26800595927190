import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { Wrapper } from './Styles';

import { SlidePresentationExample } from './Slider';

export function PdfSlider({ colors, path }) {
  if (!path) return null;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
      <Wrapper>
        <SlidePresentationExample colors={colors} fileUrl={path} />
      </Wrapper>
    </Worker>
  )
}
import clsx from "clsx";

const Button = ({ className, children, onClick }) => (
  <button
    className={clsx(
      "relative m-[0.5%] flex w-1/2 cursor-pointer items-center justify-center overflow-hidden rounded border border-solid border-transparent bg-none text-center indent-1 text-xs tracking-[4px] text-white shadow-[inset_0_0_10px_1px_rgba(0,0,0,0.3)] transition-all delay-150 duration-200 ease-in-out before:absolute before:top-0 before:left-0 before:-z-10 before:h-full before:w-full before:bg-[linear-gradient(315deg,#1969cc_0%,#254e80_100%)] before:opacity-0 before:transition-all before:delay-150 before:duration-200 before:ease-in-out lg:text-[calc(0.3vw+0.3vh+0.3vmin+0.3vmax)] [&.active]:border-[#3d7cf4] [&.active]:shadow-[0_8px_10px_0_rgba(0,0,0,0.6)] [&.active]:before:opacity-100",
      className,
    )}
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
);

export default Button;

import clsx from "clsx";
import React, { useState } from "react";
import { useSetStateContext } from "react-context-x";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { contexts } from "../../App.contexts";
import { fetchVenue } from "../../redux/reducers/venues/venueActions";
import Button from "../Button/Button";
import "./PinMap.scss";

const PinMap = ({ isOpened = false, topPositon, rightPosition, isList, close, style = {}, venue = {} }) => {
  const setLoadingVenuePage = useSetStateContext(contexts, "loadingVenuePage");
  const dispatch = useDispatch();
  const history = useHistory();

  const [closedPin, setClosedPin] = useState(false);

  const handleCloseMiniPin = () => {
    setClosedPin(!closedPin);
    close();
  };

  const handleClosePin = () => {
    setClosedPin(!closedPin);
    close();
  };

  const goToVenue = (venue) => {
    setLoadingVenuePage(true);
    setTimeout(() => {
      dispatch(fetchVenue(venue));
      history.push(`/empreendimentos/${venue.slug}`);
      setLoadingVenuePage(false);
    }, 1000);
  };

  return closedPin || isOpened ? (
    <div
      className={clsx(
        "pinMap absolute bottom-0 right-0 flex h-[80%] w-[40%] flex-col rounded bg-[#245086] p-[6px] shadow-[0_0_10px_10px_rgba(0,0,0,0.3)] lg:h-[42%] lg:w-[28%] xl:w-[23%]",
        isList
          ? "pinList relative mr-[4vw] lg:mb-[4vh] [&_p]:mb-[5px] [&_p]:text-[10px] [&_p]:text-white [&_p]:lg:text-[11px] [&_p_.icon]:text-xs [&_p_.icon]:lg:text-[13px]"
          : "pinPopup z-[999] mr-[4%]",
      )}
      style={{ ...style, ...{ right: rightPosition, top: topPositon } }}
    >
      <p>
        <i className="small map marker white alternate icon"></i>
        {venue.address} <i onClick={handleClosePin} className="close white icon"></i>
      </p>
      <div className="infosPin">
        <div className="leftInfosPin">
          <div className="logoPin" style={{ backgroundImage: `url(${venue.logo_lg_image})` }}></div>
        </div>
        <div className="rightInfosPin">
          <div className="imageEmpPin" style={{ backgroundImage: `url(${venue.facade_image})` }}></div>
        </div>
      </div>
      <Button className="!absolute left-1/2 bottom-0 translate-y-4 -translate-x-1/2" onClick={() => goToVenue(venue)}>
        Conheça
      </Button>
    </div>
  ) : (
    <div
      className={closedPin ? "miniPinMap closedPin" : "miniPinMap"}
      style={{ zIndex: 999, top: topPositon, right: rightPosition }}
      onClick={handleCloseMiniPin}
    >
      <div className="logoMiniPin"></div>
    </div>
  );
};

export default PinMap;

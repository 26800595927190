import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchVenues } from "redux/reducers/venues/venueActions";
import { useVenturesSearchContext } from "../contexts/VenturesSearchContext";
import { venueSlice } from "redux/reducers/venues/venueSlice";

const useVenturesSearch = () => {
  const dispatch = useDispatch();
  const {
    venues: { filtering: isFiltering, venueNeighborhoodIds },
  } = useSelector((state) => state, shallowEqual);

  const { setSearchFieldValue, setIsUsingSearch } = useVenturesSearchContext();

  const searchVentures = (search) => {
    let params = { search };

    if (isFiltering) {
      params.venueNeighborhoodIds = venueNeighborhoodIds;
    }

    dispatch(fetchVenues(params));
  };

  const {
    actions: { clearSearch },
  } = venueSlice;

  const backToVenturesList = () => {
    let params = {};

    if (isFiltering) {
      params.venueNeighborhoodIds = venueNeighborhoodIds;
    }

    dispatch(clearSearch());
    setSearchFieldValue("");
    setIsUsingSearch(false);
    dispatch(fetchVenues(params));
  };

  return { backToVenturesList, searchVentures };
};

export default useVenturesSearch;

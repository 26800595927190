import { useRef, useState, useEffect } from "react";
import { icons } from "../Components/VenueMenu/icons";
import Scroller from "../Components/Scroller/Scroller";
import useScroll from "../Utils/useScroll";
import Pulse from "../Components/Pulse/Pulse";
import clsx from "clsx";

export default function Videos({ venue, onOpenVideosModal }) {
  const scrollableRef = useRef(null);
  const wrapperRef = useRef(null);
  const [scrollUpdatedPos, setScrollUpdatedPos] = useState(0);

  const {
    colors: { active_font_color, active_primary_color, background_color, font_color, primary_color },
    venue_videos,
  } = venue;

  const videoCount = venue_videos.length;
  const haveThreeOrMoreVideos = videoCount >= 3;
  const hasOnlyOneVideo = videoCount === 1;

  useScroll(scrollableRef);

  function handlePlayVideo(current_id) {
    onOpenVideosModal(current_id, venue_videos, "videos");
  }

  useEffect(() => {
    console.log({ venue });
  }, [venue]);

  return (
    <section className={clsx("venue-gallery_wrapper", haveThreeOrMoreVideos ? "overshadow" : "")} ref={wrapperRef}>
      <div
        className={`venue-gallery_content video-content ${hasOnlyOneVideo ? "single-video" : ""}`}
        ref={scrollableRef}
        onScroll={(e) => setScrollUpdatedPos(e.target.scrollLeft)}
      >
        {venue_videos.map(({ id, title, video }) => {
          return (
            <div className={`venue-gallery_content-item videos ${hasOnlyOneVideo ? "single" : ""}`} key={id}>
              <div className="video-item_wrapper" onClick={() => handlePlayVideo(id)}>
                <div className="overlay opacity-80" style={{ backgroundColor: primary_color }} />
                <video className="player">
                  <source src={video} type="video/webm" />
                  Seu browser não suporta vídeos.
                </video>
                <Pulse />
                <div className="player-button">{icons?.["play_button"]?.icon?.(font_color)}</div>
              </div>
              <p>{title}</p>
            </div>
          );
        })}
      </div>
      <Scroller
        scrollRef={scrollableRef}
        wrapperRef={wrapperRef}
        scrollUpdatedPos={scrollUpdatedPos}
        fabColor={active_primary_color}
        fabSvgColor={active_font_color}
        scrollbarColor={background_color}
        scrollbarTrackColor={primary_color}
        style={{
          marginTop: "-22px",
        }}
      />
    </section>
  );
}

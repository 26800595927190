import LandscapeRequirement from "./components/LandscapeRequirement";
import LargerScreenRequirement from "./components/LargerScreenRequirement";

const ScreenRequirements = ({ children }) => {
  return (
    <div className="screen-requirements">
      {children}
      <LandscapeRequirement />
      <LargerScreenRequirement />
    </div>
  );
};

export default ScreenRequirements;

import { Route, Switch } from "react-router-dom";
import UpperMenu from "./Components/UpperMenu/UpperMenu";
import Enterprises from "./Pages/Enterprises";
import Initial from "./Pages/Initial";
import Institutional from "./Pages/Institutional";
import Venue from "./Pages/Venue";

export function Routes() {
  return (
    <>
      <Switch>
        <Route path="/empreendimentos/:slug" component={Venue} />
        <Route path="/empreendimentos" component={Enterprises} />
        <Route path="/sobre-nos" component={Institutional} />
        <Route path="/" component={Initial} />
      </Switch>
      <UpperMenu />
    </>
  );
}

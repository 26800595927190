import AnimationBackground from "Components/AnimationBackground/AnimationBackground";

const EnterprisesPageContainer = ({ children }) => {
  return (
    <div className="findFilters relative flex h-full w-full items-center justify-center overflow-hidden bg-[url('./Media/bgFind.png')] bg-contain bg-center bg-no-repeat">
      <AnimationBackground />
      {children}
    </div>
  );
};

export default EnterprisesPageContainer;

import Scroller from "../Components/Scroller/Scroller";
import { useMemo, useRef, useState } from "react";
import useScroll from "../Utils/useScroll";
import Pulse from "../Components/Pulse/Pulse";
import clsx from "clsx";

export default function Units({ venue, onOpenUnitsModal }) {
  const scrollableRef = useRef(null);
  const wrapperRef = useRef(null);
  const [scrollUpdatedPos, setScrollUpdatedPos] = useState(0);
  useScroll(scrollableRef);

  function handleToggleFullscreen(current_id) {
    onOpenUnitsModal(current_id, venue.venue_units, "units");
  }

  const firstUnitList = useMemo(() => {
    if (venue.venue_units.length > 5) {
      return [...venue.venue_units].splice(
        0,
        venue.venue_units.length % 2 ? venue.venue_units.length / 2 + 1 : venue.venue_units.length / 2
      );
    }

    return venue.venue_units;
  }, [venue.venue_units]);

  const secondUnitList = useMemo(
    () =>
      [...venue.venue_units].splice(
        venue.venue_units.length % 2 ? venue.venue_units.length / 2 + 1 : venue.venue_units.length / 2
      ),
    [venue.venue_units]
  );

  return (
    <section
      className={clsx("venue-gallery_wrapper", "units", venue.venue_units.length >= 5 ? "overshadow" : "")}
      ref={wrapperRef}
    >
      <div className="units-wrapper" ref={scrollableRef} onScroll={(e) => setScrollUpdatedPos(e.target.scrollLeft)}>
        <div
          className="venue-gallery_content units-content"
          style={venue.venue_units.length >= 5 ? { width: `${(venue.venue_units.length / 10) * 100}%` } : {}}
        >
          {firstUnitList.map((unit) => (
            <div
              className="venue-gallery_content-item units"
              key={unit.id}
              onClick={() => handleToggleFullscreen(unit.id)}
            >
              <Pulse />
              <div style={!unit.area ? { minHeight: "100%", maxHeight: "100%" } : {}}>
                <img src={unit.image} alt={unit.description} />
              </div>
              {unit.area && (
                <p>
                  {unit.area}m<sup>2</sup>
                </p>
              )}
            </div>
          ))}
        </div>
        {venue.venue_units.length > 5 && (
          <div
            className="venue-gallery_content units-content"
            style={{ width: `${(venue.venue_units.length / 10) * 100}%` }}
          >
            {secondUnitList.map((unit) => (
              <div
                className="venue-gallery_content-item units"
                key={unit.id}
                onClick={() => handleToggleFullscreen(unit.id)}
              >
                <Pulse />
                <div style={!unit.area ? { minHeight: "100%", maxHeight: "100%" } : {}}>
                  <img src={unit.image} alt={unit.description} />
                </div>
                {unit.area && (
                  <p>
                    {unit.area}m<sup>2</sup>
                  </p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="units-scroller">
        <Scroller
          scrollRef={scrollableRef}
          wrapperRef={wrapperRef}
          scrollUpdatedPos={scrollUpdatedPos}
          fabColor={venue.colors.active_primary_color}
          fabSvgColor={venue.colors.active_font_color}
          scrollbarColor={venue.colors.background_color}
          scrollbarTrackColor={venue.colors.primary_color}
          style={{
            marginTop: "-22px",
          }}
        />
      </div>
    </section>
  );
}

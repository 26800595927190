import Container from "./Container";
import orientation from "Media/orientation.gif";

const LandscapeRequirement = () => {
  return (
    <Container className="larger-screen-requirement landscape:hidden">
      Esta apresentação interativa é melhor visualizada com o dispositivo na horizontal.
      <img alt="Vire o dispositivo na horizontal" className="mx-auto mt-3 w-32" src={orientation} />
    </Container>
  );
};

export default LandscapeRequirement;

import clsx from "clsx";
import { useVenturesSearchContext } from "../contexts/VenturesSearchContext";

const NoResults = ({ className }) => {
  const { showNoResultsMessage } = useVenturesSearchContext();

  return (
    <>
      {showNoResultsMessage ? (
        <div
          className={clsx(
            "absolute left-1/2 top-52 z-10 flex -translate-x-1/2 items-center text-xl tracking-wider text-white",
            className,
          )}
        >
          Nenhum empreendimento encontrado nesta busca.
        </div>
      ) : null}
    </>
  );
};

export default NoResults;
